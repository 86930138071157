import { Errors, Fields, Schema } from '../Interface/Interface';

// validate all form
export const validateForm = (field: Fields, schema: Schema): Errors => {
  let errors: Errors = {};

  // for fields
  for (const key in field) {
    // check schema
    for (const type in schema[key]) {
      switch (type) {
        case 'required': {
          if (typeof field[key] === 'string') {
            if (field[key].trim() === '') {
              errors = {
                ...errors,
                [key]: {
                  required: `Campo ${key} richiesto.`,
                },
              };
            }
          } else if (typeof field[key] === 'boolean') {
            if (!field[key]) {
              errors = {
                ...errors,
                [key]: {
                  required: `Campo ${key} richiesto.`,
                },
              };
            }
          }
          break;
        }

        case 'valid': {
          const isValid = schema[key].valid?.(field[key].trim());
          if (!isValid) {
            errors = {
              ...errors,
              [key]: {
                valid: `Campo ${key} non valido`,
              },
            };
          }
          break;
        }

        case 'minLength': {
          if (field[key].trim().length < (schema[key].minLength as number)) {
            errors = {
              ...errors,
              [key]: {
                minLength: `Inserire ${key} con un minimo di ${schema[key].minLength} caratteri`,
              },
            };
          }
          break;
        }

        case 'maxLength': {
          if (field[key].trim().length > (schema[key].maxLength as number)) {
            errors = {
              ...errors,
              [key]: {
                maxLength: `Inserire ${key} con un massimo di ${schema[key].maxLength} caratteri`,
              },
            };
          }
          break;
        }

        case 'fixedValue': {
          if (field[key].trim().length === (schema[key].fixedValue as number)) {
            errors = {
              ...errors,
              [key]: {
                maxLength: `Inserire ${key} con un valore di ${schema[key].fixedValue} caratteri`,
              },
            };
          }
        }
      }
    }
  }

  return errors;
};
