import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.root.isAuthenticated);
  const location = useLocation();

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to='/' state={{ from: location }} replace />;
};

export default ProtectedRoute;
