import { useState } from 'react';
import { Errors, Schema, UseForm, Fields } from '../Interface/Interface';
import { validateForm } from '../Utility/validateForm';

const useForm = (initialState: Fields, schema: Schema): UseForm => {
  const [field, setField] = useState<Fields>(initialState);
  const [errors, setErrors] = useState<Errors>({});

  // change field
  const changeFieldHandler = (name: string, text: string | boolean) => {
    setField({
      ...field,
      [name]: text,
    });
  };

  // submit form
  const submitHandler = (): boolean => {
    const errors = validateForm(field, schema);

    // check errors is there or not
    if (Object.keys(errors).length > 0) {
      setErrors({ ...errors });
      return false;
    } else {
      return true;
    }
  };

  return {
    field,
    errors,
    changeFieldHandler,
    submitHandler,
  };
};

export default useForm;
