import './Searchbar.scss';
import SearchIcon from '@mui/icons-material/Search';
import Input from '../Input';
import Button from '../Button/Button';
import { useEffect, useRef } from 'react';

const Searchbar = ({ placeholder, value, onValue, onSearch }) => {
  const inputRef = useRef('');

  useEffect(() => {
    if (inputRef.current) {
      if (value === inputRef.current.value) {
        setTimeout(() => {
          onSearch();
        }, 800);
      }
    }
  }, [value]);

  // change value
  const changeValueHandler = (e) => {
    onValue(e.target.value);
  };

  return (
    <div className='relative'>
      <SearchIcon className='Searchbar__icon' />
      <Input
        ref={inputRef}
        type='search'
        placeholder={placeholder}
        className='Searchbar__input'
        onChange={changeValueHandler}
        value={value}
      />
    </div>
  );
};

export default Searchbar;
