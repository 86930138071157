import './Step.scss';
import { interfaceStep } from '../../../Interface/PropsInterface';

const Step = ({ name, currentStep, value }: interfaceStep): JSX.Element => {
  const classActive = currentStep === value ? 'active' : '';
  const lastChild = value === 3 ? 'Step__content--lastChild' : '';

  return (
    <li key={value} className='Step'>
      <div className={`Step__content ${lastChild}`}>
        <div className={`Step__circle ${classActive}`}>
          <span className='Step__value'>{value}</span>
        </div>
        <h3 className={`Step__name ml-3 flex-1 ${classActive}`}>{name}</h3>
      </div>
    </li>
  );
};

export default Step;
