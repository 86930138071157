import { Fragment, useCallback, useState } from 'react';
import './UploadPrivacyPolicy.scss';
import { useDispatch } from 'react-redux';
import useHttp from '../../Hooks/useHttp';
import { interfaceUploadPrivacy } from '../../Interface/PropsInterface';
import { AsyncLogout } from '../../Redux/Actions/Actions';
import Button from '../../Reusable/Button/Button';
import Loader from '../../Reusable/Loader';
import NoteImage from '../FormCreateNote/NoteImage/NoteImage';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useNavigate } from 'react-router-dom';



const UploadPrivacyPolicy = ({
  onNotification,
  onCloseModal,
}: interfaceUploadPrivacy): JSX.Element => {
  const [screen, setScreen] = useState('Carica privacy');
  const [file, setFile] = useState<File | null>(null);
  const [image, setImage] = useState<string>('');
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  // change Image
  const changeImage = useCallback((image) => {
    const MIME_TYPE_MAP: any = {
      'image/png': 'png',
      'image/jpeg': 'jpeg',
      'image/jpg': 'jpg',
      'image/svg': 'svg',
    };
    const isValid = !!MIME_TYPE_MAP[image.type];

    // if true
    if (isValid) {
      alert('Può aggiungere solo file di tipo pdf');
    }

    setImage(URL.createObjectURL(image));
    setFile(image);
  }, []);

  // upload privacy
  const uploadPrivacyHandler = async (): Promise<any> => {
    const formData = new FormData();
    formData.append('', file as File);
    const response = await httpRequest(process.env.REACT_APP_UPLOADPRIVACY__API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });

    // if response go to error
    if (typeof response === 'function') {
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }
      onNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }

    // save
    onNotification({
      type: 'Success',
      message: 'Badge creato con successo',
    });
    onCloseModal();
  };

  // change screen
  const changeScreenHandler = () => {
    if (screen === 'Carica privacy') {
      setScreen('Visualizza privacy');
    } else {
      setScreen('Carica privacy');
    }
  };

  // watchPrivacyPolicyHandler
  const watchPrivacyPolicyHandler = () => {
    window.location.assign('https://badge.mtmproject.com/uploads/documents/privacyPolicy.pdf');
  };

  return (
    <Fragment>
      <nav className='UploadPrivacyPolicy__navigation'>
        <ul className='UploadPrivacyPolicy__list'>
          <li className='UploadPrivacyPolicy__list-item' onClick={changeScreenHandler}>
            Carica privacy
          </li>
          <li className='UploadPrivacyPolicy__list-item' onClick={changeScreenHandler}>
            Visualizza privacy
          </li>
        </ul>
      </nav>
      {screen === 'Carica privacy' && (
        <div className='UploadPrivacyPolicy'>
          {!file && <NoteImage onImage={changeImage} filePdf={true} name="Pdf" />}
          {file && (
            <div className='UploadPrivacyPolicy__content'>
              <InsertDriveFileIcon />
              <p>{file.name}</p>
            </div>
          )}
          <Button
            disabled={!image}
            title={loader ? <Loader /> : 'Carica policy'}
            additionalClass='UploadPrivacyPolicy__btn'
            onClick={uploadPrivacyHandler}
          />
        </div>
      )}
      {screen === 'Visualizza privacy' && (
        <div
          className='UploadPrivacyPolicy__content-watchPrivacyPolicy'
          onClick={watchPrivacyPolicyHandler}
        >
          <InsertDriveFileIcon />
          <p>Privacy policy</p>
        </div>
      )}
    </Fragment>
  );
};

export default UploadPrivacyPolicy;
