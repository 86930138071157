import './OverviewInfo.scss';
import { interfaceOverView } from '../../../Interface/PropsInterface';

const OverviewInfo = ({ label, value }: interfaceOverView) => {
  return (
    <div className='OverviewInfo my-4'>
      <div className='col-xs-6 flex justify-end'>
        <p className='OverviewInfo__label'>{label}</p>
      </div>
      <div className='col-xs-6'>
        <p className='OverviewInfo__value'>{value}</p>
      </div>
    </div>
  );
};

export default OverviewInfo;
