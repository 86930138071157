import { forwardRef } from 'react';
import { InputProps } from '../Interface/PropsInterface';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <input
        disabled={props.disabled}
        ref={ref}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
        name={props.name}
        className={props.className}
        checked={props.checked}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={props.onChange}
        value={props.value}
      />
    );
  },
);

export default Input;
