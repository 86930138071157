import { useCallback, useState, useEffect } from 'react';
import './Home.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../../Containers/Footer/Footer';
import Header from '../../Containers/Header/Header';
import { Transition } from 'react-transition-group';
import Modal from '../../Reusable/Modal/Modal';
import CreateBadge from './CreateBadge/CreateBadge';
import { interfaceNotification, TokenData } from '../../Interface/Interface';
import Notification from '../../Reusable/Notification/Notification';
import UploadPrivacyPolicy from '../../Components/UploadPrivacyPolicy/UploadPrivacyPolicy';
import useHttp from '../../Hooks/useHttp';
import { AsyncLogin, AsyncLogout, AsyncSaveAdmin } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../Redux/Store';
import { Admin, Errors, Fields } from '../../Interface/Interface';

const Home = () => {
  const [statusSidebar, setStatusSidebar] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalPrivacy, setModalPrivacy] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();

  const admin = useSelector((state: RootState) => state.root.admin);

  const refreshToken = useCallback(
    async () => {
      try {
        const response: TokenData = await httpRequest(`${process.env.REACT_APP_TOKEN__API}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {
            email: (admin as Admin).email,
            refreshToken: localStorage.getItem('refreshToken')
          },
        });

        localStorage.setItem('token', response.token);
        dispatch(AsyncSaveAdmin(response.user));

      } catch (e: any) {
        setStatusMessage(true);
        setType('Error');
        setMessage(e.message);
      }
    },
    [dispatch, httpRequest],
  );

  // effect
  useEffect(() => {
  
    refreshToken();

  }, []);

  // open sidebar
  const openSidebar = useCallback(() => {
    setStatusSidebar(true);
  }, []);

  // close sidebar
  const closeSidebar = useCallback(() => {
    setStatusSidebar(false);
  }, []);

  // status modal
  const StatusModal = useCallback(() => {
    setStatusModal((prev) => !prev);
  }, []);

  // open modal privacy
  const openModalPrivacy = useCallback(() => {
    setModalPrivacy(true);
  }, []);

  // close modal privacy
  const closeModalPrivacy = useCallback(() => {
    setModalPrivacy(false);
  }, []);

  // create notification
  const createNotification = useCallback((notification: interfaceNotification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  return (
    <main className='Home'>
      <Transition in={statusMessage} timeout={500} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationLeft--${state}`}
          />
        )}
      </Transition>
      <Transition in={statusModal} timeout={500} appear unmountOnExit>
        {(state) => (
          <Modal title='Crea badge' onClose={StatusModal} animation={`modal--${state}`}>
            <CreateBadge onNotification={createNotification} onCloseModal={StatusModal} />
          </Modal>
        )}
      </Transition>
      <Transition in={modalPrivacy} timeout={500} appear unmountOnExit>
        {(state) => (
          <Modal
            title='Privacy policy'
            onClose={closeModalPrivacy}
            animation={`modal--${state}`}
            navigation={true}
          >
            <UploadPrivacyPolicy
              onNotification={createNotification}
              onCloseModal={closeModalPrivacy}
            />
          </Modal>
        )}
      </Transition>
      <Sidebar status={statusSidebar} onClose={closeSidebar} />
      <div className='Home__main'>
        <Header onMenu={openSidebar} onModal={StatusModal} onModalPrivacy={openModalPrivacy} />
        <Outlet />
        <Footer />
      </div>
    </main>
  );
};

export default Home;
