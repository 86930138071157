import './Dropdown.scss';
import { interfaceDropdown } from '../../Interface/PropsInterface';
import Button from '../Button/Button';
import CloseIcon from '@mui/icons-material/Close';

const Dropdown = ({
  dropdownType,
  header,
  list,
  onItemChoice,
  title,
  icon,
  children,
  titleBtn,
  onClick,
  animation,
  onClose,
}: interfaceDropdown) => {
  const className =
    dropdownType === 'select'
      ? `Dropdown--select ${animation}`
      : `Dropdown flex flex-col ${animation}`;

  // item choiced
  const itemChoiceHandler = (item: string): void => {
    onItemChoice?.(item);
    onClose();
  };

  return (
    <div className={className}>
      {header && (
        <div className='Dropdown__header p-4'>
          <h1 className='Dropdown__title'>{title}</h1>
          {!icon && (
            <span className='Dropdown__box relative' onClick={onClose}>
              <CloseIcon className='Dropdown__icon' />
            </span>
          )}
          {icon && icon}
        </div>
      )}
      {!list && (
        <div className='Dropdown__body'>
          {children}
          <Button title={titleBtn as string} onClick={onClick} additionalClass='w-full' />
        </div>
      )}
      {list && (
        <ul className='Dropdown__list'>
          {list.map((item) => {
            return (
              <li key={item} className='Dropdown__item' onClick={() => itemChoiceHandler(item)}>
                {item}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
