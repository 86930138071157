import './StepBar.scss';
import { interfaceStepBar } from '../../Interface/PropsInterface';
import Step from './Step/Step';

const StepBar = ({ step }: interfaceStepBar) => {
  return (
    <div className='w-full'>
      <ul className='StepBar__list'>
        <Step currentStep={step} name='Profilo' value={1} />
        <Step currentStep={step} name='Informazioni personali' value={2} />
        <Step currentStep={step} name='Crea utente' value={3} />
      </ul>
    </div>
  );
};

export default StepBar;
