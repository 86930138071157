import './Hero.scss';
import { HeroProps } from '../../Interface/PropsInterface';

const Hero = ({ image, alt }: HeroProps) => {
  return (
    <figure className='Hero'>
      <img className='Hero__image' src={image} alt={alt} />
    </figure>
  );
};

export default Hero;
