import './Footer.scss';

const Footer = (): JSX.Element => {
  return (
    <footer className='Footer px-8 py-4'>
      <p className='Footer__text'>© MTM Project Software & Training</p>
    </footer>
  );
};

export default Footer;
