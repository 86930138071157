const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const login = {
  email: '',
  password: '',
};

export const loginSchema = {
  email: {
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  password: {
    minLength: 6,
  },
};

export const formProfileUser = {
  name: '',
  surname: '',
  password: '',
  email: '',
  type: '',
};

export const schemaProfileUser = {
  name: {
    required: true,
  },
  surname: {
    required: true,
  },
  email: {
    required: true,
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  typeAccount: {
    required: true,
  },
};

export const formInformationsUser = {
  nickname: '',
  telephone: '',
  cap: '',
  town: '',
  birthday: '',
  homeAddress: '',
  lat: '',
  lng: '',
};

export const schemaInformationsUser = {
  phone: {
    minLength: 10,
    maxLength: 10,
    valid: (value: any) => {
      if (value.match(phoneRegex)) {
        return true;
      } else {
        return false;
      }
    },
  },
  cap: {
    minLength: 5,
    maxLength: 5,
  },
};

export const schemaSetting = {
  name: {
    required: true,
  },
  surname: {
    required: true,
  },
  email: {
    required: true,
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  password: {
    required: true,
    minLength: 6,
  },
};

export const resetPassword = {
  password: '',
};

export const schemaResetPassword = {
  password: {
    minLength: 6,
  },
};

export const schemaUserInfo = {
  name: {
    required: true,
  },
  surname: {
    required: true,
  },
  email: {
    required: true,
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  cf: {
    minLength: 16,
    maxLength: 16,
  },
  type: {
    required: true,
  },
};

export const formResetPassword = {
  email: '',
};

export const schemaFormResetPassword = {
  email: {
    required: true,
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};

export const notificationForm = {
  title: '',
  message: '',
  email: '',
};

export const schemaNotificationForm = {
  title: {
    required: true,
  },
  message: {
    required: true,
  },
  email: {
    valid: (value: any) => {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};

export const NoteForm = {
  image: '',
  title: '',
  subtitle: '',
  slug: '',
};

export const schemaNoteForm = {
  image: {
    required: true,
  },
  title: {
    required: true,
  },
  subtitle: {
    required: true,
  },
};
