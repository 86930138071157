import { useState, useCallback, useEffect } from 'react';
import './Users.scss';
import TableLabels from '../../Containers/TableLabels/TableLabels';
import Card from '../../Reusable/Card/Card';
import TableList from '../../Containers/TableList/TableList';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';
import { interfaceNotification, User } from '../../Interface/Interface';
import { TableUsersLables, UsersLables } from '../../Utility/TableLabels';
import HeaderTableUsers from '../../Components/HeaderTableUsers/HeaderTableUsers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store';
import useHttp from '../../Hooks/useHttp';
import { AsyncLogout, AsyncSaveAllUsers, AsyncUpdateUser } from '../../Redux/Actions/Actions';
import { useNavigate } from 'react-router-dom';



const Users = (): JSX.Element => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.root.users);
  const navigate = useNavigate();


  
  // get Users
  const getUsers = useCallback(async (): Promise<void> => {
    const response: any = await httpRequest(process.env.REACT_APP_USERS__API, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        setStatusMessage(true);
        setType('Error');
        setMessage(response().message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // message
      setStatusMessage(true);
      setType('Error');
      setMessage(response().message);
      return;
    }

    // save users
    dispatch(AsyncSaveAllUsers(response.user));
    setRefresh(false);
  }, [httpRequest, dispatch]);

  // refresh user
  useEffect(() => {
    if (!refresh) return;

    getUsers();
  }, [refresh, getUsers]);

  // create notification
  const createNotification = useCallback((notification: interfaceNotification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  // refresh page
  const onRefresh = useCallback(() => {
    setRefresh(true);
  }, []);

  const disabledUser = async (user: any) => {
    const newUser = {
      ...user,
      disabled: !user.disabled,
    };
    const response: any = await httpRequest(process.env.REACT_APP_UPDATE__API, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newUser),
    });

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        setStatusMessage(true);
        setType('Error');
        setMessage(response().message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // message
      setStatusMessage(true);
      setType('Error');
      setMessage(response().message);
      return;
    }

    // save users
    dispatch(AsyncUpdateUser(response.user));
  };

  return (
    <div className='col-xs-12 py-8 px-4'>
      <Transition in={statusMessage} timeout={500} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <Card>
        <HeaderTableUsers onNotification={createNotification} onRefresh={onRefresh} />
        <div className='Users__wrapper'>
          <table className='Users__table w-full'>
            <thead>
              <TableLabels isLabelModify={true} labels={TableUsersLables} />
            </thead>
            <TableList
              isModify={true}
              list={users}
              loader={loader}
              errorMessage='Nessun utente trovato'
              pathModify='/Home/ModificaUtente'
              routeParams={true}
              labelsTable={UsersLables}
              disabledUser={disabledUser}
            />
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Users;
