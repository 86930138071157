import './Field.scss';
import Input from '../Input';
import { FieldProps } from '../../Interface/PropsInterface';

const Field = ({
  disabled = false,
  label,
  type,
  placeholder,
  onChange,
  value,
  error,
  icon,
}: FieldProps) => {
  return (
    <div className='Field'>
      <label htmlFor={label} className='Field__label'>
        {label}
      </label>
      <div className='Field__content'>
        <Input
          disabled={disabled}
          className='Field__content-input'
          type={type}
          name={label}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {icon && icon}
        {error && <p className='Field__error'>{error}</p>}
      </div>
    </div>
  );
};

export default Field;
