import { useEffect } from 'react';
import './ProfileUser.scss';
import Button from '../../Reusable/Button/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { interfaceProfileUser } from '../../Interface/PropsInterface';
import useForm from '../../Hooks/useForm';
import { formProfileUser, schemaProfileUser } from '../../Utility/Form';
import Card from '../../Reusable/Card/Card';
import useGeneratePassword from '../../Hooks/useGeneratePasssword';
import Field from '../../Reusable/Field/Field';
import Profile from '../../Assets/Icons/profile.png';
import TypeAccount from './TypeAccount/TypeAccount';

const ProfileUser = ({ onNextStep, onUpdateUser }: interfaceProfileUser): JSX.Element => {
  const { field, errors, changeFieldHandler, submitHandler } = useForm(
    formProfileUser,
    schemaProfileUser,
  );
  const generatePassword = useGeneratePassword(16);
  const titleBtn: JSX.Element = (
    <div className='flex items-center justify-center'>
      <h1 className='ProfileUser__footer__text'>Next</h1>
      <KeyboardArrowRightIcon />
    </div>
  );

  // generate password effect
  useEffect(() => {
    changeFieldHandler('password', generatePassword());
  }, [generatePassword]);

  // change fields
  const changeFieldsHandler = (name: string, value: string): void => {
    changeFieldHandler(name, value);
  };

  // next step
  const nextStepHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const isFormValid = submitHandler();

    // check is valid or not
    if (typeof isFormValid == 'string') {
      return;
    }

    // next step
    onUpdateUser({ ...field });
    onNextStep();
  };

  return (
    <Card>
      <form className='pt-4' onSubmit={nextStepHandler} noValidate>
        <div className='row py-4 px-8'>
          <div className='col-xs-12 col-sm-3'>
            <h1 className='ProfileUser__label'>Avatar</h1>
          </div>
          <div className='col-xs-12 col-sm-9'>
            <figure className='ProfileUser__avatar'>
              <img src={Profile} alt='avatar' />
            </figure>
          </div>
        </div>
        <div className='ProfileUser__field mt-6 px-8'>
          <Field
            label='Nome'
            type='text'
            placeholder='Mario'
            onChange={(e) => changeFieldsHandler('name', e.target.value)}
            value={field.name}
            error={errors.name?.required}
          />
        </div>
        <div className='ProfileUser__field px-8'>
          <Field
            label='Cognome'
            type='text'
            placeholder='Rossi'
            onChange={(e) => changeFieldsHandler('surname', e.target.value)}
            value={field.surname}
            error={errors.surname?.required}
          />
        </div>
        <div className='ProfileUser__field px-8'>
          <Field
            label='Email'
            type='email'
            placeholder='Inserisci la tua email'
            onChange={(e) => changeFieldsHandler('email', e.target.value)}
            value={field.email}
            error={
              errors.email?.required
                ? errors.email?.required
                : errors.email?.valid
                ? errors.email?.valid
                : ''
            }
          />
        </div>
        <div className='ProfileUser__field px-8'>
          <Field
            disabled={true}
            label='Password ( autogenerata dal sistema per motivi di sicurezza )'
            type='password'
            onChange={() => {}}
            value={field.password}
          />
        </div>
        <div className='row items-center mb-4 px-8'>
          <div className='col-xs-12 col-sm-3'>
            <h1 className='ProfileUser__label'>Tipo d'account</h1>
          </div>
          <div className='col-xs-12 col-sm-9'>
            <div className='Field__field flex flex-wrap'>
              <TypeAccount
                value='Admin'
                onSelect={(value: string) => changeFieldHandler('type', value)}
              />
              <TypeAccount
                value='Segreteria'
                onSelect={(value: string) => changeFieldHandler('type', value)}
              />
              <TypeAccount
                value='Utente'
                onSelect={(value: string) => changeFieldHandler('type', value)}
              />
            </div>
          </div>
        </div>
        <div className='ProfileUser__footer py-4 px-8 flex justify-end'>
          <Button title={titleBtn} additionalClass='ProfileUser__btn' />
        </div>
      </form>
    </Card>
  );
};

export default ProfileUser;
