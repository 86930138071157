import './ListItem.scss';
import { Link } from 'react-router-dom';
import { ListItemProps } from '../../../Interface/PropsInterface';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PasswordIcon from '@mui/icons-material/Password';
import SubListItem from './SubListItem/SubListItem';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

const ListItem = ({
  name,
  path,
  subList,
  onSelect,
  activeSubList,
  onClose,
}: ListItemProps): JSX.Element => {
  const { pathname } = useLocation();

  // render icon
  const renderIcon = (link: string) => {
    switch (link) {
      case 'Home':
        return <HomeIcon className='ListItem__icon' />;

      case 'Utenti':
        return <GroupIcon className='ListItem__icon' />;

      case 'Badge':
        return <BadgeIcon className='ListItem__icon' />;

      case 'Buoni pasto':
        return <ConfirmationNumberIcon className='ListItem__icon' />;

      case 'Blog Aziendale':
        return <AssignmentIcon className='ListItem__icon' />;

      case 'Notifica Push':
        return <NotificationsIcon className='ListItem__icon' />;

      case 'Account':
        return <ManageAccountsIcon className='ListItem__icon' />;

      case 'Reset password':
        return <PasswordIcon className='ListItem__icon' />;

      default:
        return;
    }
  };

  return (
    <li className='ListItem'>
      <Link
        to={path}
        className={
          subList
            ? 'ListItem__link'
            : path === pathname
            ? 'ListItem__link active'
            : 'ListItem__link '
        }
        onClick={() => onSelect({ name, path, subList })}
      >
        {renderIcon(name)}
        <p className='ListItem__link-name'>{name}</p>
        {activeSubList && <KeyboardArrowUpIcon className='ListItem__icon' />}
        {!activeSubList && subList && <ExpandMoreIcon className='ListItem__icon' />}
      </Link>
      <Transition in={activeSubList} timeout={500} unmountOnExit>
        {(state) => (
          <ul className={`ListItem__sublist ListItem__sublist--${state}`}>
            {subList?.map((subLink, index) => {
              return (
                <SubListItem
                  key={index}
                  path={subLink.path}
                  name={subLink.name}
                  onClose={onClose}
                />
              );
            })}
          </ul>
        )}
      </Transition>
    </li>
  );
};

export default ListItem;
