import { useState } from 'react';
import './HeaderTableNote.scss';
import RefreshIcon from '@mui/icons-material/Refresh';
import Searchbar from '../../Reusable/Searchbar/Searchbar';
import useHttp from '../../Hooks/useHttp';
import { useDispatch } from 'react-redux';
import { AsyncLogout, AsyncSaveNote } from '../../Redux/Actions/Actions';
import { useNavigate } from 'react-router-dom';

const HeaderTableNote = ({ onRefresh }) => {
  const [title, setTitle] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // search
  const searchHandler = async () => {

    var requestUrl = "";

    if(title == ""){
      requestUrl = `${process.env.REACT_APP_GETNOTE__API}`;
    }else{
      requestUrl = `${process.env.REACT_APP_GETNOTE__API}/filter/${title}`;
    }

    const response = await httpRequest(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status !== 200) {
      // check if status is 401
      if (response.status === 401) {
        alert(response.message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      alert(response.message);
      return;
    }

    dispatch(AsyncSaveNote(response.notice));
  };

  // refresh handler
  const onRefreshHandler = () => {
    onRefresh();
    setTitle('');
    setIsSearched(false);
  };

  const changeTitleHandler = (e) => {
    setTitle(e);
  };

  return (
    <div className='HeaderTableUsers py-4 px-5'>
      <div className='row items-center justify-between'>
        <div className='col-xs-12 col-md-4 col-lg-3 relative'>
          <Searchbar
            placeholder='Inserisci titolo'
            value={title}
            onValue={changeTitleHandler}
            onSearch={searchHandler}
          />
        </div>
        <div className='HeaderTableUsers__refresh flex items-center justify-end col-xs-12 col-md-6'>
          {isSearched && (
            <div className='HeaderTableUsers__refresh__wrapper flex justify-end'>
              <div className='HeaderTableUsers__box' onClick={onRefreshHandler}>
                <RefreshIcon className='HeaderTableUsers__icon' />
                <p className='HeaderTableUsers__text'>Reset</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderTableNote;
