import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import HeaderTableNote from '../../Components/HeaderTableNote/HeaderTableNote';
import TableLabels from '../../Containers/TableLabels/TableLabels';
import TableList from '../../Containers/TableList/TableList';
import useHttp from '../../Hooks/useHttp';
import { AsyncLogout, AsyncSaveNote } from '../../Redux/Actions/Actions';
import { RootState } from '../../Redux/Store';
import Card from '../../Reusable/Card/Card';
import Notification from '../../Reusable/Notification/Notification';
import { labelsNote, TableNoteLabels } from '../../Utility/TableLabels';
import { useNavigate } from 'react-router-dom';


const Notices = (): JSX.Element => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const notice = useSelector((state: RootState) => state.root.notice);
  const navigate = useNavigate();

  
  // save path
  useEffect(() => {
    localStorage.setItem('path', 'Home/Avvisi');
  }, []);

  // get Note
  const getNote = useCallback(async (): Promise<void> => {
    const response: any = await httpRequest(process.env.REACT_APP_GETNOTE__API, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        setStatusMessage(true);
        setType('Error');
        setMessage(response().message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // message
      setStatusMessage(true);
      setType('Error');
      setMessage(response().message);
      return;
    }

    // save users
    dispatch(AsyncSaveNote(response.notice));
  }, [httpRequest, dispatch]);

  // effect for get note
  useEffect(() => {
    if (!refresh) return;

    getNote();
  }, [getNote, refresh]);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  const onRefresh = () => {
    setRefresh(true);
  };

  return (
    <div className='col-xs-12 py-8 px-4'>
      <Transition in={statusMessage} timeout={800} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <Card>
        <HeaderTableNote onRefresh={onRefresh} />
        <div className='Users__wrapper'>
          <table className='Users__table w-full'>
            <thead>
              <TableLabels isLabelModify={true} labels={TableNoteLabels} />
            </thead>
            <TableList
              disableOption={false}
              isModify={true}
              list={notice}
              loader={loader}
              errorMessage='Nessuna notizia aziendale trovato'
              pathModify='/Home/Modifica-avviso'
              routeParams={true}
              labelsTable={labelsNote}
            />
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Notices;
