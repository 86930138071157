import './ErrorFallback.scss';

const ErrorFallback = (): JSX.Element => {
  return (
    <div>
      <h1>Qualcosa è andato storto</h1>
      <p></p>
    </div>
  );
};

export default ErrorFallback;
