import Loader from '../../Reusable/Loader';
import './SuspenseLoader.scss';

const SuspenseLoader = () => {
  return (
    <div className='SuspenseLoader'>
      <Loader />
    </div>
  );
};

export default SuspenseLoader;
