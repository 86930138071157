import { Fragment, useEffect, useCallback, useState } from 'react';
import StepBar from '../../Containers/StepBar/StepBar';
import ProfileUser from '../../Components/ProfileUser/ProfileUser';
import InformationUser from '../../Components/InformationsUser/InformationUser';
import OverviewUser from '../../Components/OverviewUser/OverviewUser';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';
import { interfaceNotification } from '../../Interface/Interface';

const CreateUser = () => {
  const [step, setStep] = useState<number>(1);
  const [user, setUser] = useState<object>({});
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  // update user
  const updateUser = useCallback(
    (data: object): void => {
      const newUser = { ...user, ...data };
      setUser({ ...newUser });
    },
    [user],
  );

  // next step
  const nextStep = (): void => {
    setStep((prev) => prev + 1);
  };

  // prev step
  const prevStep = (): void => {
    setStep((prev) => prev - 1);
  };

  // create notification
  const createNotification = useCallback((notification: interfaceNotification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  return (
    <Fragment>
      <Transition in={statusMessage} timeout={800} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <div className='w-full flex py-8'>
        <div className='col-xs-12 col-md-10 col-lg-8 mx-auto'>
          <StepBar step={step} />
          {step === 1 && <ProfileUser onNextStep={nextStep} onUpdateUser={updateUser} />}
          {step === 2 && (
            <InformationUser
              onPrevStep={prevStep}
              onNextStep={nextStep}
              onUpdateUser={updateUser}
            />
          )}
          {step === 3 && (
            <OverviewUser onPrevStep={prevStep} user={user} onNotification={createNotification} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateUser;
