import './Button.scss';
import { ButtonProps } from '../../Interface/PropsInterface';

const Button = ({
  disabled = false,
  title,
  variant = 'primary',
  size = 'md',
  onClick,
  additionalClass,
}: ButtonProps) => {
  const className = `btn-${variant} btn-${size} ${additionalClass ? additionalClass : ''}`;

  return (
    <button disabled={disabled} className={className} onClick={onClick}>
      {title}
    </button>
  );
};

export default Button;
