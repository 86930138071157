import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import useHttp from '../../Hooks/useHttp';
import Notification from '../../Reusable/Notification/Notification';
import Card from '../../Reusable/Card/Card';
import TableLabels from '../../Containers/TableLabels/TableLabels';
import { labelsVoucher, labelsVoucherGrouped, TableVoucherLables, TableVoucherLablesGrouped } from '../../Utility/TableLabels';
import TableList from '../../Containers/TableList/TableList';
import HeaderTableVoucher from '../../Components/HeaderTableVoucher/HeaderTableVoucher';
import { AsyncLogout, AsyncDeleteVoucher } from '../../Redux/Actions/Actions';
import Modal from '../../Reusable/Modal/Modal';
import Field from '../../Reusable/Field/Field';
import Button from '../../Reusable/Button/Button';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';



const Voucher = () => {
  const [modal, setModal] = useState(false);
  const [dataIsLoad, setDataIsLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 5, 0, 0).getTime(),
  );
  const [finishDate, setFinishDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 0).getTime(),
  );

  const [email, setEmail] = useState('');
  const [singleView, setSingleView] = useState(true);
  const [voucherList, setVoucherList] = useState([]);
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // save path
  useEffect(() => {
    localStorage.setItem('path', 'Home/BuoniPasto');
  }, []);

  // get vocuher
  const getVoucher = useCallback(async () => {
    const response = await httpRequest(
      `${
        singleView ? process.env.REACT_APP_VOUCHER__API : process.env.REACT_APP_VOUCHER_GROUPED__API
      }/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    setLoader(true);

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        setStatusMessage(true);
        setType('Error');
        setMessage(response().message);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // message
      setLoader(false);
      setStatusMessage(true);
      setType('Error');
      setMessage(response().message);
      setDataIsLoad(false);
      return;
    }

    // save voucher
    setVoucherList([...response?.voucher]);
    setDataIsLoad(true);
    setRefresh(false);
    setLoader(false);
  }, [httpRequest, dispatch]);

  // refresh voucher
  useEffect(() => {
    if (!refresh && dataIsLoad) return;

    getVoucher();
  }, [refresh, getVoucher, dataIsLoad]);

  // create notification
  const createNotification = useCallback((notification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  // refresh page
  const onRefresh = useCallback(() => {
    setRefresh(true);
  }, []);

  // loader
  const statusLoader = useCallback(() => {
    setLoader((prev) => !prev);
  }, []);

  // update voucher
  const updateVoucher = (voucher) => {
    setVoucherList([...voucher]);
  };

  // badge upload modal
  const ModalBadge = () => {
    setModal(true);
  };

  const ModalStartDate = (startDate) => {
    setStartDate(startDate);
  };

  const ModalEndDate = (endDate) => {
    setFinishDate(endDate);
  };

  const ModalEmail = (email) => {
    setEmail(email);
  };

  const ModalSwitch = (singleView) => {
    setSingleView(singleView);
  };

  // status modal
  const StatusModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  // start date
  const changeStartHandler = (e) => {
    setStartDate(new Date(e.target.value).getTime());
  };

  // finish date
  const changeFinishHandler = (e) => {
    setFinishDate(new Date(e.target.value).setHours(24, 0, 0, 0));
  };

  // upload voucher
  const downloadVoucherReportHandler = async () => {
    //check date
    if (startDate === 0 || finishDate === 0) {
      createNotification({
        type: 'Error',
        message: "Inserisci un'intervallo di date",
      });

      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_GENERATEREPORTVOUCHER__API}/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const blob = await response.blob();

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }
    createNotification({
      type: 'Success',
      message: 'Report scaricato con successo',
    });
    fileDownload(
      blob,
      `ReportBuoniPastoCompleto_${moment(startDate).format('DD-MM-YYYY')}_${moment(
        finishDate,
      ).format('DD-MM-YYYY')}.xlsx`,
    );
  };

  const downloadGroupedVoucherReportHandler = async () => {
    //check date
    if (startDate === 0 || finishDate === 0) {
      createNotification({
        type: 'Error',
        message: "Inserisci un'intervallo di date",
      });

      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_GENERATEREPORT_GROUPED_VOUCHER__API}/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const blob = await response.blob();

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }
    createNotification({
      type: 'Success',
      message: 'Report scaricato con successo',
    });
    fileDownload(
      blob,
      `ReportBuoniPasto_Raggruppati_${moment(startDate).format('DD-MM-YYYY')}_${moment(
        finishDate,
      ).format('DD-MM-YYYY')}.xlsx`,
    );
  };

  // delete badge
  const onDeleteVoucher = useCallback(async (id) => {
    if (window.confirm("Sei sicuro di volere eliminare il buono pasto selezionato?")) {
      const response = await fetch(`${process.env.REACT_APP_DELETEVOUCHER__API}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({uuid: id}),
      });
  
      // if response go to error
      if (typeof response === 'function') {

        // check if status is 401
        if (response().status === 401) {
          createNotification({
            type: 'Error',
            message: response().message,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          dispatch(AsyncLogout());
          alert("Sessione Scaduta");
          navigate('/');
        }
  
        // normal error
        createNotification({
          type: 'Error',
          message: response().message,
        });
  
        return;
      }
  
      // normal error
      createNotification({
        type: 'Success',
        message: "Buono pasto eliminato con successo. Refresha la pagina",
      });
  
      dispatch(AsyncDeleteVoucher(response.voucher));
    }
  }, []);

  return (
    <div className='col-xs-12 py-8 px-4'>
      <Transition in={statusMessage} timeout={800} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <Transition in={modal} timeout={800} appear unmountOnExit>
        {(state) => (
          <Modal
            title='Download report voucher'
            onClose={StatusModal}
            animation={`modal--${state}`}
          >
            <div className='Badges__upload'>
              {/*<div className='mt-4 col-xs-12'>
                <Field type='date' label='Data inizio' onChange={changeStartHandler} />
              </div>
              <div className='mt-4 col-xs-12'>
                <Field type='date' label='Data fine' onChange={changeFinishHandler} />
              </div>*/}

              <Button title='Genera report completo' onClick={downloadVoucherReportHandler} />
              <br />
              <Button
                title='Genera report voucher raggruppati'
                onClick={downloadGroupedVoucherReportHandler}
              />
            </div>
          </Modal>
        )}
      </Transition>
      <Card>
        <HeaderTableVoucher
          onNotification={createNotification}
          onLoader={statusLoader}
          onRefresh={onRefresh}
          onUpdateVoucher={updateVoucher}
          onUpload={ModalBadge}
          onStartDateSet={ModalStartDate}
          onEndDateSet={ModalEndDate}
          onEmailSet={ModalEmail}
          onSwitchHandler={ModalSwitch}
        />
        <div className='Badge__wrapper'>
          <table className='Badge__table'>
            <thead>
              <TableLabels 
              isLabelModify={false} 
              labels={singleView ? TableVoucherLables : TableVoucherLablesGrouped} />
            </thead>

            <TableList
              isModify={false}
              list={voucherList}
              loader={loader}
              errorMessage={
                'Nessun buono pasto trovato (Se non hai selezionato nessuna data specifica, i buoni pasto recuperati saranno quelli del mese corrente)'
              }
              labelsTable={singleView ? labelsVoucher : labelsVoucherGrouped}
              deleteBtn={singleView}
              onDelete={onDeleteVoucher}
            />
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Voucher;
