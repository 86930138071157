import './OverviewUser.scss';
import Cover from '../../Assets/Images/cover__createUser.jpg';
import Avatar from '../../Assets/Icons/profile.png';
import Button from '../../Reusable/Button/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { interfaceOverviewUser } from '../../Interface/PropsInterface';
import OverviewInfo from './OverviewInfo/OverviewInfo';
import useHttp from '../../Hooks/useHttp';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { AsyncCreateUser, AsyncLogout } from '../../Redux/Actions/Actions';
import Loader from '../../Reusable/Loader';
import { useNavigate } from 'react-router-dom';

const OverviewUser = ({ onPrevStep, user, onNotification }: interfaceOverviewUser): JSX.Element => {
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const titleBtn: JSX.Element = (
    <div className='flex items-center justify-center'>
      <KeyboardArrowLeftIcon />
      <h1 className='OverviewUser__footer__titleBtn ml-1'>Precedente</h1>
    </div>
  );

  // prev step
  const prevStepHandler = (): void => {
    onPrevStep();
  };

  // create user
  const createUserHandler = async () => {
    const createUser = {
      ...user,
      name: user.name!.trim(),
      surname: user.surname!.trim(),
      idUtente: uuidv4(),
      privacyPolicy: false,
      cf: '',
      resetPasswordExpires: null,
      resetPasswordToken: '',
    };

    // http request
    const response = await httpRequest(process.env.REACT_APP_CREATEUSER__API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(createUser),
    });

    // if response return error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      onNotification({
        type: 'Error',
        message: response().error,
      });
      return;
    }

    // save user in redux
    dispatch(AsyncCreateUser(createUser));
    onNotification({
      type: 'Success',
      message: 'Utente creato con successo',
    });
  };

  return (
    <div className='OverviewUser mt-8 border-solid border-grey-200 border-1'>
      <figure className='OverviewUser__cover flex'>
        <img className='flex-1' src={Cover} alt='cover' />
      </figure>
      <div className='OverviewUser__contentProfile flex justify-center'>
        <figure className='OverviewUser__contentProfile__avatar'>
          <img src={Avatar} alt='avatar' />
        </figure>
      </div>
      <div className='py-6'>
        <OverviewInfo label='Full name:' value={(user.name as string) + (user.surname as string)} />
        <OverviewInfo label='Email:' value={user.email as string} />
        <OverviewInfo label="Tipo d'account:" value={user.type as string} />
        <OverviewInfo label='Data di nascita:' value={user.birthday as string} />
        <OverviewInfo label='Soprannome:' value={user.nickname as string} />
        <OverviewInfo label='Telefono:' value={user.phone as string} />
        <OverviewInfo label='Città:' value={user.town as string} />
        <OverviewInfo label='Indirizzo casa:' value={user.homeAddress as string} />
        <OverviewInfo label='Cap:' value={user.cap as string} />
      </div>
      <div className='OverviewUser__footer py-2 px-8 flex items-center justify-between'>
        <div className='my-2'>
          <Button title={titleBtn} additionalClass='btn--secondary' onClick={prevStepHandler} />
        </div>
        <div className='my-2'>
          <Button title={loader ? <Loader /> : 'Crea utente'} onClick={createUserHandler} />
        </div>
      </div>
    </div>
  );
};

export default OverviewUser;
