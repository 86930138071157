import { useCallback } from 'react';


const useGeneratePassword = (lenght: number) => {
  // generate password
  const generatePassword = useCallback((): string => {
    const regex = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomPassowrd = "";

    // for block for generate password
    for(let i = 0; i < lenght; i++) {
        const number = Math.floor(Math.random() * regex.length);
        randomPassowrd = randomPassowrd + regex.charAt(number);
    };

    return randomPassowrd;
  },[lenght]);


  return generatePassword;
};


export default useGeneratePassword;