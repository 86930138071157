import './SubListItem.scss';
import { SubListItemProps } from '../../../../Interface/PropsInterface';
import { Link, useLocation } from 'react-router-dom';

const SubListItem = ({ name, path, onClose }: SubListItemProps): JSX.Element => {
  const { pathname } = useLocation();

  // close sidebar
  const closeSidebarHandler = (): void => {
    onClose();
  };

  return (
    <li className='SubListItem'>
      <Link
        onClick={closeSidebarHandler}
        to={path}
        className={path === pathname ? 'SubListItem__item active' : 'SubListItem__item'}
      >
        <span className='SubListItem__item-icon' />
        <p className='SubListItem__item-text'>{name}</p>
      </Link>
    </li>
  );
};

export default SubListItem;
