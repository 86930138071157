import React, { useEffect, useState } from 'react';
import './HeaderTableBadge.scss';
import Field from '../../Reusable/Field/Field';
import Button from '../../Reusable/Button/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Transition } from 'react-transition-group';
import Dropdown from '../../Reusable/Dropdown/Dropdown';
import useHttp from '../../Hooks/useHttp';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncLogout, AsyncSaveAllBadge } from '../../Redux/Actions/Actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const HeaderTableBadge = ({ onNotification, onRefresh, onCorrect, onUpload, onStartDateSet, onEndDateSet, onEmailSet }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState(new Date().setHours(5, 0, 0, 0));
  const [finishDate, setFinishDate] = useState(new Date().setHours(23, 59, 0, 0));
  const [isSearched, setIsSearched] = useState(false);
  const [searchingMode, setSearchingMode] = useState(false);
  const [dropdownUser, setDropdownUser] = useState(false);
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.root.users);
  const navigate = useNavigate();

  const searchEmailBadgeHandler = async (tempEmail) => {

    const response = await httpRequest(
      `${process.env.REACT_APP_BADGE__API}/${startDate}/${finishDate}/${tempEmail != null ? tempEmail : email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }
      onNotification({
        type: 'Error',
        message: response().message,
      });
      setIsSearched(true);
      return;
    }

    // save in redux
    closeDropdownHandler();
    dispatch(AsyncSaveAllBadge(response.badge));
    setIsSearched(true);


  };

  // effect
  useEffect(() => {
    /*if (email && startDate && finishDate) {

      searchEmailBadgeHandler();
    }*/

    if(searchingMode){
      searchBadgeHandler();
      setSearchingMode(false);
    }

  }, [email, startDate, finishDate]);

  // status dropdown
  const statusDropdownHandler = () => {
    setShowDropdown(true);
  };

  // close dropdown
  const closeDropdownHandler = () => {
    setShowDropdown(false);
  };
  

  // call api for search data
  const searchBadgeHandler = async (stDate, edDate) => {

    if (!startDate && !finishDate) {
      return;
    }

    if (email) {
      const response = await httpRequest(
        `${process.env.REACT_APP_BADGE__API}/${startDate}/${finishDate}/${email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      // if response go to error
      if (typeof response === 'function') {
        // check if status is 401
        if (response().status === 401) {
          onNotification({
            type: 'Error',
            message: response().message,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          dispatch(AsyncLogout());
          alert("Sessione Scaduta");
          navigate('/');
        }
        onNotification({
          type: 'Error',
          message: response().message,
        });
        setIsSearched(true);
        return;
      }

      // save in redux
      closeDropdownHandler();
      dispatch(AsyncSaveAllBadge(response.badge));
      setIsSearched(true);
    } else {
      const response = await httpRequest(
        `${process.env.REACT_APP_BADGE__API}/${startDate}/${finishDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      // if response go to error
      if (typeof response === 'function') {
        // check if status is 401
        if (response().status === 401) {
          onNotification({
            type: 'Error',
            message: response().message,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          dispatch(AsyncLogout());
          alert("Sessione Scaduta");
          navigate('/');
        }
        onNotification({
          type: 'Error',
          message: response().message,
        });
        setIsSearched(true);
        return;
      }

      // save in redux
      closeDropdownHandler();
      dispatch(AsyncSaveAllBadge(response.badge));
      setIsSearched(true);
    }
  };

  // const change start date
  const changeStartDateHandler = (e) => {
    setStartDate(new Date(e.target.value).getTime());
    onStartDateSet(new Date(e.target.value).getTime());
  };

  // const change finish date
  const changeFinishDateHandler = (e) => {
    setFinishDate(new Date(e.target.value).setHours(23, 59, 0, 0));
    onEndDateSet(new Date(e.target.value).getTime());
  };


  const changeCurrentMonthHandler = (e) => {

    var date = new Date();

    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 5, 0, 0).getTime();
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59 ,0).getTime(); 

    setStartDate(firstDayOfMonth);
    onStartDateSet(firstDayOfMonth);

    setFinishDate(lastDayOfMonth);
    onEndDateSet(lastDayOfMonth);

    setStartDate(firstDayOfMonth);
    setFinishDate(lastDayOfMonth);

    setSearchingMode(true);
  };

  const changeLastMonthDateHandler = (e) => {

    var date = new Date();

    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1, 5, 0, 0).getTime();
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59 ,0).getTime(); 

    setStartDate(firstDayOfMonth);
    onStartDateSet(firstDayOfMonth);

    setFinishDate(lastDayOfMonth);
    onEndDateSet(lastDayOfMonth);

    setStartDate(firstDayOfMonth);
    setFinishDate(lastDayOfMonth);

    setSearchingMode(true);

  };


  // refresh handler
  const onRefreshHandler = () => {
    onRefresh();
    setEmail('');
    setStartDate(new Date().setHours(5, 0, 0, 0));
    setFinishDate(new Date().setHours(23, 59, 0, 0));
    setIsSearched(false);
  };

  // upload
  const uploadHandler = () => {
    onUpload();
  };

  const correctHandler = () => {
    onCorrect();
  };

  const createListUser = () => {



    const copyArray = [...users].sort((a, b) => a.surname.localeCompare(b.surname));
    const Users = copyArray.map((user) => {
      return user.name + " " + user.surname + " " + user.email;
    });

    return Users;
  };

  // user choiced
  const userChoiced = (user) => {
    const copyArray = [...users];
    const User = copyArray.find((item) => {
      const userEmail = item.email;
      return (user.split(" ")[2]).trim() === userEmail.trim();
    });
    setEmail(User.email);
    onEmailSet(User.email);

    searchEmailBadgeHandler(User.email);
  };

  // status dropdown user
  const statusDropdownUserHandler = () => {
    setDropdownUser((prev) => !prev);
  };

  return (
    <div className='HeaderTableBadge py-4 px-5'>
      <div className='row justify-between'>
        <div className='col-xs-12 col-md-4 col-lg-3 relative'>
          <div className='relative'>
            <div
              className='HeaderTableBadge__select CreateBadge__select flex justify-between relative'
              onClick={statusDropdownUserHandler}
            >
              {email ? email : 'Scegli utente'}
            </div>
            <Transition in={dropdownUser} timeout={500} appear unmountOnExit>
              {(state) => (
                <Dropdown
                  dropdownType='select'
                  header={false}
                  list={createListUser()}
                  onItemChoice={(user) => userChoiced(user)}
                  animation={`dropdown--select--${state}`}
                  onClose={statusDropdownUserHandler}
                />
              )}
            </Transition>
          </div>
        </div>
        <div className='HeaderTableBadge__filter col-xs-12 col-md-6'>
          <div className='HeaderTableBadge__content'>
            {startDate && finishDate ? (
              <p className='small_date_range'>
                {moment(startDate).format('DD-MM-YYYY')} - {moment(finishDate).format('DD-MM-YYYY')}{' '}
              </p>
            ) : (
              <p> </p>
            )}
            <div className='filterBadge'>
              <div className='relative'>
                <div className='filterBadge__box' onClick={statusDropdownHandler}>
                  <FilterListIcon className='filterBadge__icon' />
                  <p className='filterBadge__text'>Filtra</p>
                </div>
                <Transition in={showDropdown} timeout={500} unmountOnExit>
                  {(state) => (
                    <Dropdown
                      dropdownType='normal'
                      header={true}
                      title='Filtra badge'
                      titleBtn='Filtra'
                      onClick={searchBadgeHandler}
                      animation={`dropdown--${state}`}
                      onClose={closeDropdownHandler}
                    >
                      <div className='Dropdown__row'>
                        <div className='Dropdown__col'>
                          <Field
                            label="Data d'inizio"
                            type='date'
                            onChange={changeStartDateHandler}
                          />
                        </div>
                        <div className='Dropdown__col'>
                          <Field 
                          label='Data fine' 
                          type='date'
                          onChange={changeFinishDateHandler} />
                        </div>
                        <div className='Dropdown__col'>
                          <Button 
                          title='Mese Attuale' 
                          onClick={changeCurrentMonthHandler} />
                          &nbsp;
                          <Button 
                          title='Mese Precedente' 
                          onClick={changeLastMonthDateHandler} />
                        </div>
                        &nbsp;
                      </div>
                    </Dropdown>
                  )}
                </Transition>
              </div>
            </div>
            <div className='mx-2'>
              <div className='HeaderTableBadge__box--upload' onClick={correctHandler}>
                <ArrowDownwardIcon className='HeaderTableBadge__icon--upload' />
                <p className='HeaderTableBadge__text--upload'>Correggi Badge</p>
              </div>
            </div>
            <div className='mx-2'>
              <div className='HeaderTableBadge__box--upload' onClick={uploadHandler}>
                <ArrowDownwardIcon className='HeaderTableBadge__icon--upload' />
                <p className='HeaderTableBadge__text--upload'>Report</p>
              </div>
            </div>
            {isSearched && (
              <div className='mx-2'>
                <div className='HeaderTableBadge__box' onClick={onRefreshHandler}>
                  <RefreshIcon className='HeaderTableBadge__icon' />
                  <p className='HeaderTableBadge__text'>Reset</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTableBadge;
