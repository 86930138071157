import { combineReducers , createStore , applyMiddleware } from 'redux';
import Reducer from './Reducer/Reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';


// reducer
const rootReducer = combineReducers({
    root: Reducer
});

// store
export type RootState = ReturnType<typeof rootReducer>
const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(thunk)));

export default store;