import './InformationsUser.scss';
import { interfaceInformationUser } from '../../Interface/PropsInterface';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '../../Reusable/Button/Button';
import Card from '../../Reusable/Card/Card';
import useForm from '../../Hooks/useForm';
import Field from '../../Reusable/Field/Field';
import { formInformationsUser, schemaInformationsUser } from '../../Utility/Form';
import Flags from 'country-flag-icons/react/3x2';

const InformationUser = ({
  onPrevStep,
  onNextStep,
  onUpdateUser,
}: interfaceInformationUser): JSX.Element => {
  const { field, errors, changeFieldHandler, submitHandler } = useForm(
    formInformationsUser,
    schemaInformationsUser,
  );
  const nextTitleBtn: JSX.Element = (
    <div className='flex items-center justify-center'>
      <h1 className='InformationUser__footer__text'>Successivo</h1>
      <KeyboardArrowRightIcon />
    </div>
  );
  const prevTitleBtn: JSX.Element = (
    <div className='flex items-center justify-center'>
      <KeyboardArrowLeftIcon />
      <h1 className='InformationUser__footer__text ml-1'>Precedente</h1>
    </div>
  );

  // change fields
  const changeFieldsHandler = (name: string, value: string): void => {
    changeFieldHandler(name, value);
  };

  // prev step
  const prevStepHandler = (): void => {
    onPrevStep();
  };

  // next step
  const nextStepHandler = (): void => {
    const isFormValid = submitHandler();

    // check is valid or not
    if (typeof isFormValid == 'string') {
      return;
    }

    if(field.phone) {
      const updateUser = {
        ...field,
        phone: `+39 ${field.phone}`,
      };
      onUpdateUser({ ...updateUser });
    }

    // next step
    onUpdateUser({ ...field });
    onNextStep();
  };

  return (
    <Card>
      <div className='py-4'>
        <div className='InformationUser__field mt-6 px-8'>
          <Field
            label='Data di nascita'
            type='date'
            placeholder='Inserire data di nascita'
            onChange={(e) => changeFieldsHandler('birthday', e.target.value)}
            value={field.birthday}
          />
        </div>
        <div className='InformationUser__field mt-6 px-8'>
          <div className='Field'>
            <label htmlFor='Telefono'>Telefono</label>
            <div className='InformationUser__field__phone relative'>
              <input
                className='InformationUser__input'
                name='Telefono'
                type='number'
                placeholder='Inserire numero telefonico'
                onChange={(e) => changeFieldsHandler('phone', e.target.value)}
                value={field.phone}
              />
              <div className='InformationUser__prefixPhone'>
                <Flags.IT title='Italy' className='InformationUser__icon' />
                <p className='InformationUser__text'>+ 39</p>
              </div>
              {errors?.phone && (
                <p className='InformationUser__error'>
                  {errors.phone?.minLength
                    ? errors.phone?.minLength
                    : errors.cap?.maxLength
                    ? errors.cap?.maxLength
                    : errors.cap?.valid}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='InformationUser__field mt-6 px-8'>
          <Field
            label='Città'
            type='text'
            placeholder='Inserire città'
            onChange={(e) => changeFieldsHandler('town', e.target.value)}
            value={field.town}
          />
        </div>
        <div className='InformationUser__field mt-6 px-8'>
          <Field
            label='Cap'
            type='text'
            placeholder='Inserire cap'
            onChange={(e) => changeFieldsHandler('cap', e.target.value)}
            value={field.cap}
            error={errors.cap?.minLength ? errors.cap?.minLength : errors.cap?.maxLength}
          />
        </div>
        <div className='InformationUser__field mt-6 px-8'>
          <Field
            label='Indirizzo di casa'
            type='text'
            placeholder='Inserire indirizzo di casa'
            onChange={(e) => changeFieldsHandler('homeAddress', e.target.value)}
            value={field.homeAddress}
          />
        </div>
      </div>
      <div className='InformationUser__footer py-4 px-8 flex flex-wrap items-center justify-between'>
        <Button title={prevTitleBtn} additionalClass='btn--secondary' onClick={prevStepHandler} />
        <Button
          title={nextTitleBtn}
          additionalClass='InformationUser__btn'
          onClick={nextStepHandler}
        />
      </div>
    </Card>
  );
};

export default InformationUser;
