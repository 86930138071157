import { useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AsyncLogin, AsyncLogout, AsyncSaveAdmin } from '../Redux/Actions/Actions';

const useHttp = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const httpRequest = useCallback(async (url, options): Promise<any> => {
    setLoader((prev) => !prev);

    try {
      // take response
      const response: any = await axios({
        method: options.method,
        url: url,
        headers: options.headers,
        data: options.body || null,
      });

      // return promise
      return new Promise((resolve, reject) => {
        if (response?.status && response?.status !== 200) {

          if(response?.status === 401){

            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            dispatch(AsyncLogout());
            setLoader(false);

            alert("Sessione Scaduta");
            
            navigate('/');



          }else{
            setLoader(false);
            reject({
              status: response.status,
              message: response?.message || 'Errore , riprova',
            });
          }


        } else {
          setLoader(false);
          resolve(response.data);
        }
      });
    } catch (e) {
      
      if (String(e).includes("401")){
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        setLoader(false);

        alert("Sessione Scaduta");
        
        navigate('/');
      }else {
        return new Promise((_, reject) => {
          setLoader(false);
          reject({
            status: 503,
            message: 'Qualcosa è andato storto' + e,
          });
        });
      }



    }
  }, []);

  return { httpRequest, loader };
};

export default useHttp;
