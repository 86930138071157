import React, { useCallback, useState } from 'react';
import './FormCreateNote.scss';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import NoteImage from './NoteImage/NoteImage';
import Field from '../../Reusable/Field/Field';
import Button from '../../Reusable/Button/Button';
import useForm from '../../Hooks/useForm';
import { NoteForm, schemaNoteForm } from '../../Utility/Form';
import useHttp from '../../Hooks/useHttp';
import { AsyncLogout } from '../../Redux/Actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Reusable/Loader';
import { interfaceFormCreateNote } from '../../Interface/PropsInterface';
import { RootState } from '../../Redux/Store';

const FormCreateNote = ({ onNotification }: interfaceFormCreateNote): JSX.Element => {
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  const [isDescriptionEdit, setIsDescriptionEdit] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const { field, errors, changeFieldHandler, submitHandler } = useForm(NoteForm, schemaNoteForm);
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const admin = useSelector((state: RootState) => state.root.admin);

  // change Image
  const changeImage = useCallback((image) => {
    changeFieldHandler('image', URL.createObjectURL(image));
    setFile(image);
  }, []);

  // change title
  const changeTitleHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeFieldHandler('title', e.target.value);
  };

  // change subtitle
  const changeSubtitleHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeFieldHandler('subtitle', e.target.value);
  };

  // change slug
  const changeSlugHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeFieldHandler('slug', e.target.value);
  };

  // editor change
  const onEditorStateChange = (editorState: any): void => {
    setEditorState(editorState);
    setIsDescriptionEdit(true);
  };

  // create note
  const createNoteHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    // call submithandler
    const formIsValid = submitHandler();

    // check description
    if (!isDescriptionEdit) {
      setErrorDescription('Campo descrizione richiesto.');
    }

    // check form is valid
    if (typeof formIsValid === 'string') {
      return;
    }

    // check if slug not contains #
    if (!field.slug.includes('#')) {
      alert('Inserire # prima di ogni slug');
      return;
    }

    // create form data
    const formData = new FormData();
    formData.append('title', field.title);
    formData.append('subtitle', field.subtitle);
    // formData.append('author', admin.name + ' ' + admin.surname);
    formData.append('noteImage', file as Blob);
    formData.append('description', stateToHTML(editorState.getCurrentContent()));
    formData.append('slug', field.slug);

    // call api
    const response = await httpRequest(process.env.REACT_APP_CREATENOTE__API, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    });

    // if response return error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        dispatch(AsyncLogout());
      }
      onNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }

    // success
    onNotification({
      type: 'Success',
      message: 'Avviso creato con successo',
    });
  };

  return (
    <form className='FormCreateNote' onSubmit={createNoteHandler}>
      <NoteImage onImage={changeImage} filePdf={false} />
      <Field
        type='text'
        label='Titolo'
        placeholder='Inserisci titolo'
        onChange={changeTitleHandler}
        value={field.title}
        error={errors['title']?.required}
      />
      <Field
        type='text'
        label='Sottotitolo'
        placeholder='Inserisci sottotitolo'
        onChange={changeSubtitleHandler}
        value={field.subtitle}
        error={errors['subtitle']?.required}
      />
      <div className='mb-4'>
        <label className='Field__label'>Descrizione</label>
        <Editor
          editorState={editorState}
          wrapperClassName='wrapper-class'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          onEditorStateChange={onEditorStateChange}
        >
          <textarea
            className='FormCreateNote__text'
            disabled
            value={stateToHTML(editorState.getCurrentContent())}
          />
        </Editor>
        <p className='Field__error'>{errorDescription}</p>
      </div>
      <div className='FormCreateNote__slug'>
        <Field
          type='text'
          label='Slug'
          placeholder='Inserisci slug'
          onChange={changeSlugHandler}
          value={field.slug}
        />
      </div>
      <div className='FormCreateNote__contentBtn'>
        <Button title={loader ? <Loader /> : 'Crea avviso'} />
      </div>
    </form>
  );
};

export default FormCreateNote;
