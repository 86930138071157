import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// options
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: "Badge timbrati nell'arco della settimana",
    },
  },
};

// labels
const labels = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì'];

const DashboardChart = ({ dataGraph }) => {
  const data = {
    labels,
    datasets: [
      {
        label: 'Badge',
        data: dataGraph?.map((number) => number),
        borderColor: '#003f86',
        backgroundColor: 'rgba(0, 63, 134, 0.5)',
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default DashboardChart;
