import { Fragment, useCallback, useLayoutEffect, useState } from 'react';
import './Sidebar.scss';
import Logo from '../../Assets/Icons/Logo__dark.png';
import SidebarList from '../../Utility/SidebarList';
import ListItem from './ListItem/ListItem';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { SidebarProps } from '../../Interface/PropsInterface';
import React from 'react';
import { SidebarListItem } from '../../Interface/Interface';

const Sidebar = ({ status, onClose }: SidebarProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [activeSubList, setActiveSubList] = useState<string[]>(['']);
  const { pathname } = useLocation();

  // resize
  const resize = (): void => {
    setIsMobile(window.innerWidth < 1199 ? true : false);
  };

  // effect for resize mode
  useLayoutEffect(() => {
    if (window.innerWidth < 1199) {
      setIsMobile(true);
    }
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  // selected link
  const selectedLinkHandler = useCallback(
    (link: SidebarListItem) => {
      if (link.name === 'Home') {
        localStorage.removeItem('path');
      }
      if (!link.subList) {
        onClose();
        setActiveSubList(['']);
        return;
      }
      const copyActiveSubList = [...activeSubList];
      const isSubListExist = copyActiveSubList.findIndex((item) => item === link.name);

      // check if exist or not
      if (isSubListExist >= 0) {
        copyActiveSubList.splice(isSubListExist, 1);
        setActiveSubList([...copyActiveSubList]);
      } else if (isSubListExist < 0 && link.subList) {
        setActiveSubList((prev) => [...prev, link.name]);
      }
    },
    [activeSubList, onClose],
  );

  return (
    <Fragment>
      {isMobile && status && <div className='Sidebar__overlay' />}
      <Transition in={isMobile && status} timeout={500} unmountOnExit>
        {(state) => (
          <aside className={`Sidebar Sidebar--${state}`}>
            <header className='Sidebar__header'>
              <img className='Sidebar__header-logo' src={Logo} alt='logo' />
            </header>
            <div className='Sidebar__body'>
              <ul className='Sidebar__body-list'>
                {SidebarList.map((link, index) => {
                  return (
                    <ListItem
                      key={index}
                      name={link.name}
                      path={link.path || pathname}
                      subList={link?.subList}
                      onSelect={(linkSelected) => selectedLinkHandler(linkSelected)}
                      activeSubList={activeSubList.includes(link.name)}
                      onClose={onClose}
                    />
                  );
                })}
              </ul>
            </div>
          </aside>
        )}
      </Transition>
      {!isMobile && (
        <aside className='Sidebar'>
          <header className='Sidebar__header'>
            <img className='Sidebar__header-logo' src={Logo} alt='logo' />
          </header>
          <div className='Sidebar__body'>
            <ul className='Sidebar__body-list'>
              {SidebarList.map((link, index) => {
                return (
                  <ListItem
                    key={index}
                    name={link.name}
                    path={link.path || pathname}
                    subList={link?.subList}
                    onSelect={(linkSelected) => selectedLinkHandler(linkSelected)}
                    activeSubList={activeSubList.includes(link.name)}
                    onClose={onClose}
                  />
                );
              })}
            </ul>
          </div>
        </aside>
      )}
    </Fragment>
  );
};

export default React.memo(Sidebar);
