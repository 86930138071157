import './TableLabels.scss';
import { interfaceTableLabels } from '../../Interface/PropsInterface';

const TableLabels = ({ isLabelModify, labels }: interfaceTableLabels): JSX.Element => {
  return (
    <tr className='TableLabels__content'>
      {labels?.map((item, index) => {
        return (
          <th key={index} className='TableLabels__column'>
            <h1 className='TableLabels__text'>{item}</h1>
          </th>
        );
      })}
      {isLabelModify && (
        <th className='TableLabels__column'>
          <h1 className='TableLabels__text'>Dettagli</h1>
        </th>
      )}
    </tr>
  );
};

export default TableLabels;
