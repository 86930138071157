import * as ActionType from './ActionType';
import { User, Action, Admin, Badge, Note } from '../../Interface/Interface';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

// login admin
const loginAdmin = (admin: Admin): Action => {
  return {
    type: ActionType.SAVE__ADMIN,
    payload: admin,
  };
};

// async action
export const AsyncSaveAdmin = (admin: Admin): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(loginAdmin(admin));
  };
};

// login
const login = (): Action => {
  return {
    type: ActionType.LOGIN,
  };
};

// async action
export const AsyncLogin = (): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(login());
  };
};

// create user
const createUser = (user: User): Action => {
  return {
    type: ActionType.CREATE__USER,
    payload: user,
  };
};

// async action
export const AsyncCreateUser = (user: User): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(createUser(user));
  };
};

// save all badge
const saveAllBadge = (badges: Badge): Action => {
  return {
    type: ActionType.SAVE__ALL__BADGE,
    payload: badges,
  };
};

// async action
export const AsyncSaveAllBadge = (badges: Badge): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(saveAllBadge(badges));
  };
};

// save all user
const saveAllUsers = (users: Array<User>): Action => {
  return {
    type: ActionType.SAVE__ALL__USERS,
    payload: users,
  };
};

// async action
export const AsyncSaveAllUsers = (users: Array<User>): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(saveAllUsers(users));
  };
};

// logout
const logout = (): Action => {
  return {
    type: ActionType.LOGOUT,
  };
};

// async action
export const AsyncLogout = (): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(logout());
  };
};

// create badge
const createBadge = (badge: Badge): Action => {
  return {
    type: ActionType.CREATE__BADGE,
    payload: badge,
  };
};

// async action
export const AsyncCreateBadge = (badge: Badge): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(createBadge(badge));
  };
};

// update user
const updateUser = (user: User): Action => {
  return {
    type: ActionType.UPDATE__USER,
    payload: user,
  };
};

// async action
export const AsyncUpdateUser = (user: User): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(updateUser(user));
  };
};

// save all note
const saveAllNote = (note: Note): Action => {
  return {
    type: ActionType.SAVE__ALL__NOTE,
    payload: note,
  };
};

// async action
export const AsyncSaveNote = (note: Note): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(saveAllNote(note));
  };
};

const saveAllNotification = (notification: Notification): Action => {
  return {
    type: ActionType.SAVE__ALL__NOTIFICATION,
    payload: notification,
  };
};

// async action
export const AsyncSaveNotification = (
  notification: Notification,
): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(saveAllNotification(notification));
  };
};

// update note
const updateNote = (note: Note): Action => {
  return {
    type: ActionType.UPDATE__NOTE,
    payload: note,
  };
};

// async action
export const AsyncUpdateNote = (note: Note): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(updateNote(note));
  };
};

// disabled
const disabledUser = (): Action => {
  return {
    type: ActionType.DISABLES__USERS,
  };
};

// async action
export const AsyncDisabledUser = (): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(disabledUser());
  };
};

// delete
const deleteUser = (id: string): Action => {
  return {
    type: ActionType.DELETE__USER,
    payload: id,
  };
};

const deleteBadge = (id: string): Action => {
  return {
    type: ActionType.DELETE__BADGE,
    payload: id,
  };
};

const deleteVoucher = (id: string): Action => {
  return {
    type: ActionType.DELETE__VOUCHER,
    payload: id,
  };
};

// async action
export const AsyncDeleteUser = (id: string): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(deleteUser(id));
  };
};

export const AsyncDeleteBadge = (uuid: string): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(deleteBadge(uuid));
  };
};

export const AsyncDeleteVoucher = (uuid: string): ThunkAction<void, {}, {}, AnyAction> => {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>): void => {
    dispatch(deleteVoucher(uuid));
  };
};