import { useEffect, useState } from 'react';
import './HeaderTableUser.scss';
import useHttp from '../../Hooks/useHttp';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncDisabledUser, AsyncLogout, AsyncSaveAllUsers } from '../../Redux/Actions/Actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import fileDownload from 'js-file-download';
import { Transition } from 'react-transition-group';
import Dropdown from '../../Reusable/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';


const HeaderTableUsers = ({ onNotification, onRefresh }) => {
  const [email, setEmail] = useState('');
  const [dropdownUser, setDropdownUser] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.root.users);
  const navigate = useNavigate();

  
  // effect
  useEffect(() => {
    if (email) {
      const getUser = async () => {
        const response = await httpRequest(`${process.env.REACT_APP_USERS__API}/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        // if response go to error
        if (typeof response === 'function') {
          if (response().status === 401) {
            onNotification({
              type: 'Error',
              message: response().message,
            });
            dispatch(AsyncLogout());
          }

          // if error not authorizated
          onNotification({
            type: 'Error',
            message: response().message,
          });
          setIsSearched(true);
          return;
        }

        // save
        dispatch(AsyncSaveAllUsers([{ ...response.user }]));
        setIsSearched(true);
      };
      getUser();
    }
  }, [email]);

  // refresh handler
  const onRefreshHandler = () => {
    onRefresh();
    setEmail('');
    setIsSearched(false);
  };

  // upload user
  const uploadHandler = async () => {
    const response = await fetch(`${process.env.REACT_APP_GENERATEREPORTUSERS__API}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const blob = await response.blob();

    if (response.status !== 200) {
      // check if status is 401
      if (response.status === 401) {
        onNotification({
          type: 'Error',
          message: response.message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      onNotification({
        type: 'Error',
        message: response.message,
      });
      return;
    }

    onNotification({
      type: 'Success',
      message: 'Report scaricato con successo',
    });
    fileDownload(blob, 'utente.xlsx');
  };

    // create list user
    const createListUser = () => {
      const copyArray = [...users].sort((a, b) => a.surname.localeCompare(b.surname));
      const Users = copyArray.map((user) => {
        return user.name + " " + user.surname + " " + user.email;
      });
  
      return Users;
    };
  
    // user choiced
    const userChoiced = (user) => {
      const copyArray = [...users];
      const User = copyArray.find((item) => {
        const userEmail = item.email;
        return (user.split(" ")[2]).trim() === userEmail.trim();
      });
  
      setEmail(User.email);
      setDropdownUser(false);
    };

  // status dropdown user
  const statusDropdownUserHandler = () => {
    setDropdownUser((prev) => !prev);
  };

  // disabled user
  const disableUserHandler = () => {
    dispatch(AsyncDisabledUser());
    setIsSearched(true);
  };

  return (
    <div className='HeaderTableUsers py-4 px-5'>
      <div className='row items-center justify-between'>
        <div className='col-xs-12 col-md-4 col-lg-3 relative'>
          <div className='relative'>
            <div
              className='HeaderTableUsers__select CreateBadge__select flex justify-between relative'
              onClick={statusDropdownUserHandler}
            >
              {email ? email : 'Scegli utente'}
            </div>
            <Transition in={dropdownUser} timeout={500} appear unmountOnExit>
              {(state) => (
                <Dropdown
                  dropdownType='select'
                  header={false}
                  list={createListUser()}
                  onItemChoice={(user) => userChoiced(user)}
                  animation={`dropdown--select--${state}`}
                  onClose={statusDropdownUserHandler}
                />
              )}
            </Transition>
          </div>
        </div>
        <div className='HeaderTableUsers__refresh flex items-center justify-end col-xs-12 col-md-6'>
          <div className='mx-2'>
            <div className='HeaderTableUser__box--upload' onClick={uploadHandler}>
              <ArrowDownwardIcon className='HeaderTableUser__icon--upload' />
              <p className='HeaderTableUser__text--upload'>Report</p>
            </div>
          </div>
          {isSearched && (
            <div className='HeaderTableUsers__refresh__wrapper flex justify-end'>
              <div className='HeaderTableUsers__box' onClick={onRefreshHandler}>
                <RefreshIcon className='HeaderTableUsers__icon' />
                <p className='HeaderTableUsers__text'>Reset</p>
              </div>
            </div>
          )}
          <div className='mx-2'>
            <div className='HeaderTableUser__box--disableUsers' onClick={disableUserHandler}>
              <p className='HeaderTableUser__text--disableUsers'>Utenti disabilitati</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTableUsers;
