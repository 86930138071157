import React, { Fragment, useState } from 'react';
import './NoteImage.scss';
import Input from '../../../Reusable/Input';
import CardImage from '../../../Assets/Icons/card-game.png';
import { interfaceNoteImage } from '../../../Interface/PropsInterface';

const NoteImage = ({ onImage, filePdf, name }: interfaceNoteImage): JSX.Element => {
  const [content, setContent] = useState<string | null>(null);

  // drag over
  const dragOverHandler = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  // drag enter
  const dragEnterHandler = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  // drag leave
  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  // file upload
  const fileUpload = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const selectedFile = e?.dataTransfer?.files[0];

    // check if file is video or image
    if (selectedFile.type === 'video/mp4') {
      return;
    }
    setContent(URL.createObjectURL(selectedFile));
    onImage(selectedFile);
  };

  // change file
  const changeFileHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      const file = e.target.files[0];
      setContent(URL.createObjectURL(file));
      onImage(file);
    }
  };

  return (
    <div className='NoteImage'>
      <div className='NoteImage__box'>
        <div
          className='NoteImage__wrapperImage'
          onDragOver={dragOverHandler}
          onDragEnter={dragEnterHandler}
          onDragLeave={dragLeaveHandler}
          onDrop={fileUpload}
        >
          <label className='NoteImage__wrapperImage-label' htmlFor='File'>
            <Input
              className='NoteImage__wrapperImage-input'
              type='file'
              name='File'
              id='File'
              onChange={changeFileHandler}
            />
            {!content && (
              <Fragment>
                <img className='NoteImage__wrapperImage-image' src={CardImage} alt='upload file' />
                <p className='NoteImage__wrapperImage-text'>Aggiungi {name ? name : 'foto'}</p>
                <p className='NoteImage__wrapperImage-text--subtitle'>o trascina e rilascia</p>
              </Fragment>
            )}
            {!filePdf && content && (
              <img className='NoteImage__image' src={content} alt={content} />
            )}
          </label>
        </div>
      </div>
    </div>
  );
};

export default NoteImage;
