import { InterfaceSidebarList } from '../Interface/Interface';

const SidebarList: InterfaceSidebarList = [
  {
    name: 'Home',
    path: '/Home',
  },
  {
    name: 'Utenti',
    subList: [
      {
        path: '/Home/Panoramica',
        name: 'Panoramica',
      },
      {
        path: '/Home/CreaUtente',
        name: 'Crea utente',
      },
    ],
  },
  {
    name: 'Badge',
    path: '/Home/Badge',
  },
  {
    name: 'Buoni pasto',
    path: '/Home/BuoniPasto',
  },
  {
    name: 'Blog Aziendale',
    subList: [
      {
        path: '/Home/Avvisi',
        name: 'Panoramica',
      },
      {
        path: '/Home/CreaAvviso',
        name: 'Crea Notizia',
      },
    ],
  },
  {
    name: 'Notifica Push',
    path: '/Home/Notifica',
  },
  {
    name: 'Account',
    path: '/Home/Impostazioni',
  },
  {
    name: 'Reset password',
    path: '/ResetPassword',
  }
];

export default SidebarList;
