import { useEffect, useState } from 'react';
import './HeaderTableVoucher.scss';
import { useDispatch, useSelector } from 'react-redux';
import useHttp from '../../Hooks/useHttp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AsyncLogout } from '../../Redux/Actions/Actions';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Transition } from 'react-transition-group';
import Field from '../../Reusable/Field/Field';
import Button from '../../Reusable/Button/Button';
import Dropdown from '../../Reusable/Dropdown/Dropdown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const HeaderTableVoucher = ({
  onNotification,
  onLoader,
  onRefresh,
  onUpdateVoucher,
  onUpload,
  onStartDateSet,
  onEndDateSet,
  onEmailSet,
  onSwitchHandler,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [email, setEmail] = useState('');
  const [singleView, setSingleView] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 5, 0, 0).getTime(),
  );
  const [finishDate, setFinishDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 0).getTime(),
  );
  const [dropdownUser, setDropdownUser] = useState(false);
  const [searchingMode, setSearchingMode] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.root.users);
  const navigate = useNavigate();


  const searchEmailVoucherHandler = async (tempEmail) => {
    const response = await httpRequest(
      `${
        singleView ? process.env.REACT_APP_VOUCHER__API : process.env.REACT_APP_VOUCHER_GROUPED__API
      }/${startDate}/${finishDate}/${tempEmail != null ? tempEmail : email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }
      onNotification({
        type: 'Error',
        message: response().message,
      });
      setIsSearched(true);
      return;
    }

    // save in redux
    closeDropdownHandler();
    setIsSearched(true);
    onUpdateVoucher(response.voucher);
  };

  // effect
  useEffect(() => {
    /*if (email && startDate && finishDate) {

      searchEmailBadgeHandler();
    }*/

    if(searchingMode){
      searchEmailVoucherHandler();
      setSearchingMode(false);
    }

  }, [email, startDate, finishDate])

  // refresh handler
  const onRefreshHandler = () => {
    onRefresh();
    setEmail('');
    setStartDate(new Date().setHours(5, 0, 0, 0));
    setFinishDate(new Date().setHours(23, 59, 0, 0));
    setIsSearched(false);
  };

  // status dropdown
  const statusDropdownHandler = () => {
    setShowDropdown(true);
  };

  // close dropdown
  const closeDropdownHandler = () => {
    setShowDropdown(false);
  };

  // call api for search data
  const searchVoucherHandler = async () => {
    if (!startDate && !finishDate) {
      return;
    }

    const response = await httpRequest(
      `${
        singleView ? process.env.REACT_APP_VOUCHER__API : process.env.REACT_APP_VOUCHER_GROUPED__API
      }/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }
      onNotification({
        type: 'Error',
        message: response().message,
      });
      setIsSearched(true);
      return;
    }

    // save in redux
    closeDropdownHandler();
    setIsSearched(true);
    onUpdateVoucher(response.voucher);
  };

  // const change start date
  const changeStartDateHandler = (e) => {
    setStartDate(new Date(e.target.value).getTime());
    onStartDateSet(new Date(e.target.value).getTime());
  };

  // const change finish date
  const changeFinishDateHandler = (e) => {
    setFinishDate(new Date(e.target.value).setHours(23, 59, 0, 0));
    onEndDateSet(new Date(e.target.value).getTime());
  };

  const changeCurrentMonthHandler = (e) => {
    var date = new Date();

    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1, 5, 0, 0).getTime();
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 0).getTime();

    setStartDate(firstDayOfMonth);
    onStartDateSet(firstDayOfMonth);

    setFinishDate(lastDayOfMonth);
    onEndDateSet(lastDayOfMonth);

    setStartDate(firstDayOfMonth);
    setFinishDate(lastDayOfMonth);

    setSearchingMode(true);

  };

  const changeLastMonthDateHandler = (e) => {
    var date = new Date();

    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1, 5, 0, 0).getTime();
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 0).getTime();

    setStartDate(firstDayOfMonth);
    onStartDateSet(firstDayOfMonth);

    setFinishDate(lastDayOfMonth);
    onEndDateSet(lastDayOfMonth);

    setStartDate(firstDayOfMonth);
    setFinishDate(lastDayOfMonth);

    setSearchingMode(true);

  };

  // upload
  const uploadHandler = () => {
    onUpload();
  };

  const switchHandler = () => {
    setSingleView(!singleView);
    onSwitchHandler(singleView);

    searchEmailVoucherHandler();
  };

  const createListUser = () => {
    const copyArray = [...users].sort((a, b) => a.surname.localeCompare(b.surname));
    const Users = copyArray.map((user) => {
      return user.name + ' ' + user.surname + ' ' + user.email;
    });

    return Users;
  };

  // user choiced
  const userChoiced = (user) => {
    const copyArray = [...users];
    const User = copyArray.find((item) => {
      const userEmail = item.email;
      return user.split(' ')[2].trim() === userEmail.trim();
    });
    setEmail(User.email);
    onEmailSet(User.email);

    searchEmailVoucherHandler(User.email);
  };

  // status dropdown user
  const statusDropdownUserHandler = () => {
    setDropdownUser((prev) => !prev);
  };

  return (
    <div className='HeaderTableVoucher py-4 px-5'>
      <div className='row justify-between'>
        <div className='col-xs-12 col-md-4 col-lg-3 relative'>
          <div className='relative'>
            <div
              className='HeaderTableVoucher__select CreateBadge__select flex justify-between relative'
              onClick={statusDropdownUserHandler}
            >
              {email ? email : 'Scegli utente'}
            </div>
            <Transition in={dropdownUser} timeout={500} appear unmountOnExit>
              {(state) => (
                <Dropdown
                  dropdownType='select'
                  header={false}
                  list={createListUser()}
                  onItemChoice={(user) => userChoiced(user)}
                  animation={`dropdown--select--${state}`}
                  onClose={statusDropdownUserHandler}
                />
              )}
            </Transition>
          </div>
        </div>
        <div className='HeaderTableVoucher__filter col-xs-12 col-md-6'>
          <div className='HeaderTableVoucher__content'>
            {startDate && finishDate ? (
              <p className='small_date_range'>
                {moment(startDate).format('DD-MM-YYYY')} - {moment(finishDate).format('DD-MM-YYYY')}{' '}
              </p>
            ) : (
              <p> </p>
            )}
            <div className='filterBadge'>
              <div className='relative'>
                <div className='filterBadge__box' onClick={statusDropdownHandler}>
                  <FilterListIcon className='filterBadge__icon' />
                  <p className='filterBadge__text'>Filtra</p>
                </div>
                <Transition in={showDropdown} timeout={500} unmountOnExit>
                  {(state) => (
                    <Dropdown
                      dropdownType='normal'
                      header={true}
                      title='Filtra badge'
                      titleBtn='Filtra'
                      onClick={searchVoucherHandler}
                      animation={`dropdown--${state}`}
                      onClose={closeDropdownHandler}
                    >
                      <div className='Dropdown__row'>
                        <div className='Dropdown__col'>
                          <Field
                            label="Data d'inizio"
                            type='date'
                            onChange={changeStartDateHandler}
                          />
                        </div>
                        <div className='Dropdown__col'>
                          <Field label='Data fine' type='date' onChange={changeFinishDateHandler} />
                        </div>
                        <div className='Dropdown__col'>
                          <Button 
                          title='Mese Attuale' 
                          onClick={changeCurrentMonthHandler} />
                          &nbsp;
                          <Button 
                          title='Mese Precedente' 
                          onClick={changeLastMonthDateHandler} />
                        </div>
                        &nbsp;
                      </div>
                    </Dropdown>
                  )}
                </Transition>
              </div>
            </div>
            <div className='mx-2'>
              <div className='HeaderTableVoucher__box--upload' onClick={uploadHandler}>
                <ArrowDownwardIcon className='HeaderTableVoucher__icon--upload' />
                <p className='HeaderTableVoucher__text--upload'>Report</p>
              </div>
            </div>
            <div className='mx-2'>
              <div className='HeaderTableVoucher__box--upload' onClick={switchHandler}>
                <ArrowDownwardIcon className='HeaderTableVoucher__icon--upload' />
                <p className='HeaderTableVoucher__text--upload'>
                  {!singleView ? 'Raggruppati' : 'Singoli'}
                </p>
              </div>
            </div>
            {isSearched && (
              <div className='HeaderTableVoucher__refresh'>
                <div className='HeaderTableVoucher__refresh__wrapper'>
                  <div className='HeaderTableVoucher__box' onClick={onRefreshHandler}>
                    <RefreshIcon className='HeaderTableVoucher__icon' />
                    <p className='HeaderTableVoucher__text'>Reset</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTableVoucher;
