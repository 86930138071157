import './Modal.scss';
import CloseIcon from '@mui/icons-material/Close';
import { interfaceModal } from '../../Interface/PropsInterface';

const Modal = ({
  title,
  children,
  onClose,
  animation,
  navigation,
}: interfaceModal): JSX.Element => {
  const modalHeader = navigation ? 'Modal__header-navigation' : 'Modal__header';

  return (
    <div className={`Backdrop ${animation}`}>
      <div className='Modal'>
        <header className={`${modalHeader} flex items-center justify-between`}>
          <h1 className='font-sans font-regular'>{title}</h1>
          <div onClick={onClose}>
            <CloseIcon className='Modal__header__icon' />
          </div>
        </header>
        <div className='Modal__body'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
