import { useState } from 'react';
import './LoginForm.scss';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '../../Reusable/Button/Button';
import useForm from '../../Hooks/useForm';
import { login, loginSchema } from '../../Utility/Form';
import useHttp from '../../Hooks/useHttp';
import { useDispatch } from 'react-redux';
import { AsyncLogin, AsyncSaveAdmin } from '../../Redux/Actions/Actions';
import Loader from '../../Reusable/Loader';
import { LoginFormProps } from '../../Interface/PropsInterface';
import Field from '../../Reusable/Field/Field';
import { useNavigate } from 'react-router-dom';
import { LoginApi } from '../../Interface/Interface';
import env from 'react-dotenv';

const LoginForm = ({ onError }: LoginFormProps) => {
  const { field, errors, changeFieldHandler, submitHandler } = useForm(login, loginSchema);
  const { httpRequest, loader } = useHttp();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let passwordIcon: JSX.Element | undefined;

  // change email
  const changeEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeFieldHandler('email', e.target.value);
  };

  // change password
  const changePasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeFieldHandler('password', e.target.value);
  };

  // login
  const loginHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();
    try {
      const formIsValid = submitHandler();

      // check form is valid
      if (!formIsValid) {
        return;
      }
      const response: LoginApi = await httpRequest(process.env.REACT_APP_LOGIN__API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...field }),
      });
      localStorage.setItem('token', response.token);
      localStorage.setItem('refreshToken', response.refreshToken);
      dispatch(AsyncLogin());
      dispatch(AsyncSaveAdmin(response.user));
      navigate('/Home');
    } catch (e: any) {
      onError({
        type: 'Error',
        message: e.message,
      });
    }
  };

  // change visible password
  if (passwordVisible) {
    passwordIcon = (
      <span className='LoginForm__icon' onClick={() => setPasswordVisible((prev) => !prev)}>
        <VisibilityIcon />
      </span>
    );
  } else {
    passwordIcon = (
      <span className='LoginForm__icon' onClick={() => setPasswordVisible((prev) => !prev)}>
        <VisibilityOffIcon />
      </span>
    );
  }

  return (
    <form className='LoginForm' onSubmit={loginHandler} noValidate>
      <Field
        label='Email'
        type='email'
        placeholder='Inserisci email'
        onChange={changeEmailHandler}
        value={field.email}
        error={errors['email']?.valid}
      />
      <Field
        label='Password'
        type={passwordVisible ? 'text' : 'password'}
        placeholder='Inserisci password'
        onChange={changePasswordHandler}
        value={field.password}
        error={errors['password']?.minLength}
        icon={passwordIcon}
      />
      <Button
        disabled={!field.email || !field.password}
        title={loader ? <Loader /> : 'Login'}
        additionalClass='LoginForm__btn'
      />
    </form>
  );
};

export default LoginForm;
