import './TypeAccount.scss';
import { interfaceTypeAccount } from '../../../Interface/PropsInterface';
import Input from '../../../Reusable/Input';
import CheckIcon from '@mui/icons-material/Check';

const TypeAccount = ({ value, onSelect, checked }: interfaceTypeAccount): JSX.Element => {
  // change type account
  const changeTypeAccountHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSelect(e.target.value);
  };

  return (
    <div className='TypeAccount col-sm-4'>
      <div className='relative flex items-center'>
        <Input
          type='radio'
          placeholder=''
          name='typeAccount'
          onChange={changeTypeAccountHandler}
          className='TypeAccount__checkbox border-radius-full'
          value={value}
          checked={checked}
        />
        <CheckIcon className='TypeAccount__icon' />
        <label htmlFor='Admin' className='TypeAccount__label ml-2 font-xs-base'>
          {value}
        </label>
      </div>
    </div>
  );
};

export default TypeAccount;
