import './Header.scss';
import ProfileImage from '../../Assets/Icons/profile.png';
import { HeaderProps } from '../../Interface/PropsInterface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store';
import { Admin } from '../../Interface/Interface';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import BadgeIcon from '@mui/icons-material/Badge';
import SecurityIcon from '@mui/icons-material/Security';
import { AsyncLogout } from '../../Redux/Actions/Actions';
import { useNavigate } from 'react-router-dom';





const Header = ({ onMenu, onModal, onModalPrivacy }: HeaderProps) => {
  const admin: Admin = useSelector((state: RootState) => state.root.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  // open menu
  const openMenuHandler = () => {
    onMenu();
  };

  // open Modal
  const openModalHandler = () => {
    onModal();
  };

  // log out
  const logOutHandler = () => {
    localStorage.removeItem('token');
    dispatch(AsyncLogout());
    alert("Sessione Scaduta");
    navigate('/');
  };

  // open privacy
  const openPrivacyHandler = (): void => {
    onModalPrivacy();
  };

  return (
    <header className='Header'>
      <div className='Header__profile'>
        <img className='Header__profile-image' src={ProfileImage} alt='avatar' />
        <h3 className='Header__profile-text'>{admin?.name + ' ' + admin?.surname}</h3>
      </div>
      <ul className='Header__option'>
        <li className='Header__option-item' onClick={openMenuHandler}>
          <MenuIcon className='Header__item-icon' />
        </li>
        <li className='Header__option-item' onClick={openPrivacyHandler}>
          <SecurityIcon className='Header__item-icon' />
        </li>
        <li className='Header__option-item' onClick={openModalHandler}>
          <BadgeIcon className='Header__item-icon' />
        </li>
        <li className='Header__option-item' onClick={logOutHandler}>
          <LogoutIcon className='Header__item-icon' />
        </li>
      </ul>
    </header>
  );
};

export default Header;
