export const SAVE__ADMIN: string = 'SAVE__ADMIN';
export const LOGIN: string = 'LOGIN';
export const LOGOUT: string = 'LOGOUT';
export const CREATE__USER: string = 'CREATE__USER';
export const SAVE__ALL__BADGE: string = 'SAVE__ALL__BADGE';
export const SAVE__ALL__USERS: string = 'SAVE__ALL__USERS';
export const CREATE__BADGE: string = 'CREATE__BADGE';
export const UPDATE__USER: string = 'UPDATE__USER';
export const SAVE__ALL__NOTE: string = 'SAVE__ALL__NOTE';
export const SAVE__ALL__NOTIFICATION: string = 'SAVE__ALL__NOTIFICATION';
export const UPDATE__NOTE: string = 'UPDATE__NOTE';
export const DISABLES__USERS = 'DISABLES__USERS';
export const DELETE__USER = 'DELETE__USER';
export const DELETE__BADGE = 'DELETE__BADGE';
export const DELETE__VOUCHER = 'DELETE__VOUCHER';