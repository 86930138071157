import { useCallback, useEffect, useState } from 'react';
import './Badges.scss';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTableBadge from '../../Components/HeaderTableBadge/HeaderTableBadge';
import TableLabels from '../../Containers/TableLabels/TableLabels';
import TableList from '../../Containers/TableList/TableList';
import useHttp from '../../Hooks/useHttp';
import { AsyncLogout, AsyncSaveAllBadge, AsyncDeleteBadge } from '../../Redux/Actions/Actions';
import Card from '../../Reusable/Card/Card';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';
import { TableBadgeLabels, labelsBadge } from '../../Utility/TableLabels';
import Modal from '../../Reusable/Modal/Modal';
import Field from '../../Reusable/Field/Field';
import Button from '../../Reusable/Button/Button';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';



const Badges = () => {
  const [modal, setModal] = useState(false);
  const [isLoadData, setIsLoadData] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [statusMessage, setStatusMessage] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [startDate, setStartDate] = useState(new Date().setHours(5, 0, 0, 0));
  const [finishDate, setFinishDate] = useState(new Date().setHours(23, 59, 0, 0));
  const [email, setEmail] = useState('');
  const [note, setNote] = useState({});
  const { httpRequest, loader } = useHttp();
  const badges = useSelector((state) => state.root.badges);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // save path
  useEffect(() => {
    localStorage.setItem('path', 'Home/Badge');
  }, []);

  // create notification
  const createNotification = useCallback((notification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  // get badge
  const getBadges = useCallback(async (start, end) => {


    var url = "" 

    if(start != null && end != null){
      url = `${process.env.REACT_APP_BADGE__API}/${start}/${end}/${email}`
    }else{
      url = `${process.env.REACT_APP_BADGE__API}/${startDate}/${finishDate}/${email}`
    }

    const response = await httpRequest(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      setIsLoadData(false);
      return;
    }

    // save in redux
    dispatch(AsyncSaveAllBadge(response.badge));
    setRefresh(false);
    setIsLoadData(true);
  }, [dispatch, httpRequest, createNotification, email, startDate, finishDate]);

  // dowload all badge
  useEffect(() => {
    if (!refresh && isLoadData) return;
    getBadges();
  }, [getBadges, refresh, isLoadData]);

  // refresh page
  const onRefresh = () => {
    setRefresh(true);
  };

  // badge upload modal
  const ModalBadge = () => {
    setModal(true);
  };

  const onCorrect = useCallback(async () => {
    
    if(email == '' || startDate == null || finishDate == null){

      createNotification({
        type: 'Error',
        message: 'Seleziona un\'utente, una data di inizio e una data di fine, prima di proseguire',
      });

      return
    }

    var message = `Stai per correggere tutti i badge di uscita, nel periodo ${moment(startDate).format('DD-MM-YYYY')} - ${moment(finishDate).format('DD-MM-YYYY')}, per l'utente ${email}\nVuoi Procedere? Dopo aver cliccato conferma, attendere messaggio di successo`

    if (window.confirm(message)) {

      var start = new Date(startDate).setHours(5, 0, 0, 0);
      var end = new Date(finishDate).setHours(23, 59, 0, 0);

      const response = await fetch(
        `${process.env.REACT_APP_CORRECTBADGE__API}/${start}/${end}/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      // if response go to error
      if (typeof response === 'function') {
        // check if status is 401
        if (response().status === 401) {
          createNotification({
            type: 'Error',
            message: response().message,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          dispatch(AsyncLogout());
          alert("Sessione Scaduta");
          navigate('/');
        }
  
        // normal error
        createNotification({
          type: 'Error',
          message: response().message,
        });
  
        return;
      }
  
      // normal error
      createNotification({
        type: 'Successo',
        message: "Badge corretti con successo.",
      });


      getBadges(start, end);

    }

  }, [email, startDate, finishDate]);


  const ModalStartDate = (startDate) => {
    setStartDate(startDate);
  };

  const ModalEndDate = (endDate) => {
    setFinishDate(endDate);
  };

  const ModalEmail = (email) => {
    setEmail(email);
  };

  // status modal
  const StatusModal = useCallback(() => {
    setModal((prev) => !prev);
  }, []);

  // upload badge
  const downloadBadgeReportCompleteHandler = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GENERATEREPORTBADGE__API}/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const blob = await response.blob();

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }
    createNotification({
      type: 'Success',
      message: 'Report scaricato con successo',
    });
    fileDownload(
      blob,
      `ReportCompletoBadge_${moment(startDate).format('DD-MM-YYYY')}_${moment(finishDate).format(
        'DD-MM-YYYY',
      )}.xlsx`,
    );
  };

  const downloadBadgeReportWorkHoursHandler = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_GENERATEREPORTBADGE_WORKED_HOURS__API}/${startDate}/${finishDate}/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    );
    const blob = await response.blob();

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }
    createNotification({
      type: 'Success',
      message: 'Report scaricato con successo',
    });
    fileDownload(
      blob,
      `ReportOreDiLavoroBadge_${moment(startDate).format('DD-MM-YYYY')}_${moment(finishDate).format(
        'DD-MM-YYYY',
      )}.xlsx`,
    );
  };

  // change note
  const changeNote = (item) => {
    setNote({
      uuid: item.uuid,
      note: item.note
    });
  };

  const onRealase = useCallback(async () => {

    const response = await fetch(`${process.env.REACT_APP_UPDATENOTE__API}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(note),
    });

    // if response go to error
    if (typeof response === 'function') {
      // check if status is 401
      if (response().status === 401) {
        createNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // normal error
      createNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }
  }, [note]);

  // delete badge
  const onDeleteBadge = useCallback(async (id) => {
    if (window.confirm("Sei sicuro di volere eliminare il badge selezionato?")) {
      const response = await fetch(`${process.env.REACT_APP_DELETEBADGE__API}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({uuid: id}),
      });
  
      // if response go to error
      if (typeof response === 'function') {
        // check if status is 401
        if (response().status === 401) {
          createNotification({
            type: 'Error',
            message: response().message,
          });
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          dispatch(AsyncLogout());
          alert("Sessione Scaduta");
          navigate('/');
        }
  
        // normal error
        createNotification({
          type: 'Error',
          message: response().message,
        });
  
        return;
      }
  
      // normal error
      createNotification({
        type: 'Success',
        message: "Badge eliminato con successo. Refresha la pagina",
      });
  
      dispatch(AsyncDeleteBadge(response.badge));
    }
  }, []);

  return (
    <div className='col-xs-12 py-8 px-4'>
      <Transition in={statusMessage} timeout={800} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <Transition in={modal} timeout={800} appear unmountOnExit>
        {(state) => (
          <Modal title='Download report badge' onClose={StatusModal} animation={`modal--${state}`}>
            <div className='Badges__upload'>
              {/*<div className='mt-4 col-xs-12'>
                <Field type='date' label='Data inizio' onChange={changeStartHandler} />
              </div>
              <div className='mt-4 col-xs-12'>
                <Field type='date' label='Data fine' onChange={changeFinishHandler} />
              </div>*/}

              <Button title='Genera report completo' onClick={downloadBadgeReportCompleteHandler} />
              <br />
              <Button
                title='Genera report ore di lavoro'
                onClick={downloadBadgeReportWorkHoursHandler}
              />
            </div>
          </Modal>
        )}
      </Transition>
      <Card>
        <HeaderTableBadge
          onNotification={createNotification}
          onRefresh={onRefresh}
          onCorrect={onCorrect}
          onUpload={ModalBadge}
          onStartDateSet={ModalStartDate}
          onEndDateSet={ModalEndDate}
          onEmailSet={ModalEmail}
        />
        <div className='Badge__wrapper'>
          <table className='Badge__table w-full'>
            <thead>
              <TableLabels isLabelModify={false} labels={TableBadgeLabels} />
            </thead>
            <TableList
              isModify={false}
              list={badges}
              loader={loader}
              errorMessage={`Nessun badge trovato (Se non hai selezionato nessuna data specifica, i badge recuperati sono in data: ${new Date().toLocaleDateString()})`}
              labelsTable={labelsBadge}
              onUpdatedItem={changeNote}
              onRelease={onRealase}
              deleteBtn={true}
              onDelete={onDeleteBadge}
            />
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Badges;
