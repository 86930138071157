import { Fragment, useCallback, useEffect, useState } from 'react';
import './Login.scss';
import LoginForm from '../../Components/LoginForm/LoginForm';
import Hero from '../../Containers/Hero/Hero';
import { interfaceNotification, Userinfo, UserAdmin } from '../../Interface/Interface';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';
import { useDispatch } from 'react-redux';
import { AsyncLogin, AsyncLogout, AsyncSaveAdmin } from '../../Redux/Actions/Actions';
import { useNavigate } from 'react-router-dom';
import { decodeToken, isExpired } from 'react-jwt';
import useHttp from '../../Hooks/useHttp';
import Loader from '../../Reusable/Loader';
import HeroImage from '../../Assets/Images/HeroImage.png';

const Login = () => {
  const [loader, setLoader] = useState(true);
  const [statusMessage, setStatusMessage] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const { httpRequest } = useHttp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get user
  const getUser = useCallback(
    async (email) => {
      try {
        const response: UserAdmin = await httpRequest(`${process.env.REACT_APP_USERS__API}/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        dispatch(AsyncSaveAdmin(response.user));
      } catch (e: any) {
        setStatusMessage(true);
        setType('Error');
        setMessage(e.message);
      }
    },
    [dispatch, httpRequest],
  );

  // check if authenicated
  useEffect(() => {
    const token = localStorage.getItem('token') || '';

    // if token not exist
    if (!token) {
      setLoader(false);
      return;
    }
    const isTokenExpired = isExpired(token);

    // check is expired or not
    if (isTokenExpired) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      dispatch(AsyncLogout());
      setLoader(false);
      navigate('/');
    } else {
      const userInfo: Userinfo = decodeToken(token) || {};

      // check if token was decoded
      if (userInfo?._email) {
        getUser(userInfo?._email);
        dispatch(AsyncLogin());
        setLoader(false);
        navigate('/Home');
      };
    }

    return () => {
      setStatusMessage(false);
      setType('');
      setMessage('');
    };
  }, [dispatch, navigate, getUser]);

  // open toast
  const openToast = useCallback((notification: interfaceNotification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeToastHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  // loader
  if (loader) {
    return (
      <div className='Login__loader'>
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      <Transition in={statusMessage} timeout={650} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeToastHandler}
            animation={state}
            direction='Left'
          />
        )}
      </Transition>
      <div className='Login'>
        <div className='Login__container'>
          <div className='Login__row'>
            <div className='Login__hero'>
              <Hero image={HeroImage} alt='Login' />
            </div>
            <div className='Login__sign'>
              <div className='Login__sign-content'>
                <h1 className='Login__content-title'>Accedi al tuo account</h1>
                <LoginForm onError={openToast} />
              </div>
              <p className='Login__copyright'>
                Copyright 2021 | MTM Project srl | P.I. 07066530721 Via Ludovico Ariosto, 25 -
                Monopoli (BA) - 70043
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
