import { Fragment, useEffect, useCallback, useState } from 'react';
import Card from '../../Reusable/Card/Card';
import FormCreateNote from '../../Components/FormCreateNote/FormCreateNote';
import { interfaceNotification } from '../../Interface/Interface';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';

const CreateNote = (): JSX.Element => {
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  // save path
  useEffect(() => {
    localStorage.setItem('path', 'Home/CreaAvviso');
  }, []);

  // create notification
  const createNotification = useCallback((notification: interfaceNotification) => {
    setStatusMessage(true);
    setType(notification.type);
    setMessage(notification.message);
  }, []);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  return (
    <Fragment>
      <Transition in={statusMessage} timeout={800} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationRight--${state}`}
          />
        )}
      </Transition>
      <div className='w-full flex py-8'>
        <div className='col-xs-12 col-md-10 col-lg-8 mx-auto'>
          <Card>
            <FormCreateNote onNotification={createNotification} />
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateNote;
