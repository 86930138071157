import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Users from './Pages/Users/Users';
import Badges from './Pages/Badges/Badges';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import NotFound from './Pages/NotFound/NotFound';
import CreateUser from './Pages/CreateUser/CreateUser';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import Voucher from './Pages/Voucher/Voucher';
import CreateNote from './Pages/CreateNote/CreateNote';
import Notices from './Pages/Notices/Notices';
import SuspenseLoader from './Containers/SuspenseLoader/SuspenseLoader';

// Code splitting
const Setting = React.lazy(() => import('./Pages/Setting/Setting'));
const UserInfo = React.lazy(() => import('./Pages/UserInfo/UserInfo'));
const RecoveryPassword = React.lazy(() => import('./Pages/RecoveryPassword/RecoveryPassword'));
const ResetPassword = React.lazy(() => import('./Pages/ResetPassword/ResetPassword'));
const CreateNotification = React.lazy(
  () => import('./Pages/CreateNotification/CreateNotification'),
);
const NoteInfo = React.lazy(() => import('./Pages/NoteInfo/Noteinfo'));

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Navigate to='/404' />} />
        <Route path='/' element={<Login />} />
        <Route
          path='/Home'
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path='/Home/Panoramica' element={<Users />} />
          <Route
            path='/Home/ModificaUtente/:idUtente'
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <UserInfo />
              </Suspense>
            }
          />
          <Route path='/Home/CreaUtente' element={<CreateUser />} />
          <Route path='/Home/Badge' element={<Badges />} />
          <Route path='/Home/BuoniPasto' element={<Voucher />} />
          <Route path='/Home/Avvisi' element={<Notices />} />
          <Route path='/Home/CreaAvviso' element={<CreateNote />} />
          <Route
            path='/Home/Modifica-avviso/:idNote'
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <NoteInfo />
              </Suspense>
            }
          />
          <Route
            path='/Home/Notifica'
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <CreateNotification />
              </Suspense>
            }
          />
          <Route
            path='/Home/Impostazioni'
            element={
              <Suspense fallback={<SuspenseLoader />}>
                <Setting />
              </Suspense>
            }
          />
        </Route>
        <Route
          path='/resetPassword/:token'
          element={
            <Suspense fallback={<SuspenseLoader />}>
              <RecoveryPassword />
            </Suspense>
          }
        />
        <Route
          path='/ResetPassword'
          element={
            <ProtectedRoute>
              <Suspense fallback={<SuspenseLoader />}>
                <ResetPassword />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route path='/404' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
