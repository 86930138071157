import './DashboardCard.scss';
import Card from '../../Reusable/Card/Card';
import { interfaceDashboardCard } from '../../Interface/PropsInterface';
import Button from '../../Reusable/Button/Button';
import { useNavigate } from 'react-router-dom';

const DashboardCard = ({
  type = 'data',
  title,
  subtitle,
  icon,
  image,
  navigateTo,
}: interfaceDashboardCard): JSX.Element => {
  const navigate = useNavigate();

  // navigation to
  const navigationToHandler = () => {
    navigate(navigateTo as string);
  };

  switch (type) {
    case 'data': {
      return (
        <Card className='DashboardCard DashboardCard-data'>
          <div className='DashboardCard__content'>
            <h1 className='DashboardCard__title-data'>{title}</h1>
            <h3 className='DashboardCard__subtitle-data'>{subtitle}</h3>
            <div className="DashboardCard__contentBtn">
              <Button
                title='Naviga'
                additionalClass='DashboardCard__btn'
                onClick={navigationToHandler}
              />
            </div>
          </div>
          <span className='DashboardCard__boxIcon'>{icon}</span>
        </Card>
      );
    }

    case 'introduction': {
      return (
        <Card className='DashboardCard DashboardCard-introduction'>
          <div className='col-xs-6'>
            <div className='DashboardCard__content-introduction'>
              <h1 className='DashboardCard__title-introduction'>{title}</h1>
              <h3 className='DashboardCard__subtitle-introduction'>{subtitle}</h3>
            </div>
          </div>
          <div className='DashboardCard__image col-xs-6'>
            <img src={image} alt='analytics' />
          </div>
        </Card>
      );
    }

    default:
      return <div />;
  }
};

export default DashboardCard;
