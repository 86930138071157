export const TableBadgeLabels = [
  'Nome e Cognome',
  'email',
  'Luogo',
  'Tipo',
  'Data',
  'Ora',
  'Ora (non arrotondata)',
  'Verifica',
  'Ritardo',
  'Extra',
  'Parziale ore lavorate',
  'Totale ore lavorate',
  'Assenza',
  'Note',
  'Opzioni',
];
export const labelsBadge = [
  'idUtente',
  'nickname',
  'email',
  'workType',
  'badgeType',
  'date',
  'time',
  'preciseTimestamp',
  'verifyType',
  'delay',
  'extra',
  'workedTime',
  'totalWorkedTime',
  'totalNonWorkedTime',
  'note',
];
export const TableUsersLables = [
  'Email',
  'Nome',
  'Cognome',
  'Nickname',
  'Tipo account',
  'Privacy policy',
  'disattivato',
];

export const UsersLables = [
  'email',
  'name',
  'surname',
  'nickname',
  'type',
  'privacyPolicy',
  'disabled',
];

export const TableVoucherLables = ['Nome e Cognome', 'Email', 'Totale Buoni', 'Data'];
export const TableVoucherLablesGrouped = ['Nome e Cognome', 'Email', 'Totale Buoni'];

export const labelsVoucher = ['nome_cognome', 'email', 'totale_buoni', 'timestamp'];
export const labelsVoucherGrouped = ['nome_cognome', 'email', 'totale_buoni'];

export const TableNoteLabels = ['Titolo', 'Sottotitolo', 'Autore', 'Data di creazione'];
export const labelsNote = ['title', 'subtitle', 'author', 'timestamp'];

export const TableNotificationLables = [
  'Data di consegna',
  'titolo',
  'messaggio',
  'mittente',
  'destinatario',
];
export const NotificationLables = ['timestamp', 'title', 'message', 'sender', 'receiver'];
