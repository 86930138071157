import {
  interfaceInitialState,
  Action,
  Badge,
  User,
  Note,
  Notification,
} from '../../Interface/Interface';

// initialState
const initialState: interfaceInitialState = {
  admin: {},
  users: [],
  badges: [],
  notice: [],
  notification: [],
  isAuthenticated: false,
};

// reducer
const Reducer = (
  state: interfaceInitialState = initialState,
  action: Action,
): interfaceInitialState => {
  switch (action.type) {
    case 'SAVE__ADMIN': {
      return {
        ...state,
        admin: { ...(action.payload as object) },
      };
    }

    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case 'SAVE__ALL__USERS': {
      if ((action.payload as Array<User>).length === 0) {
        return {
          ...state,
          users: [],
        };
      }

      return {
        ...state,
        users: [...(action.payload as Array<User>)],
      };
    }

    case 'CREATE__USER': {
      const copyUsers = [...state.users];
      const updateUsers = [...copyUsers, { ...(action.payload as object) }];

      return {
        ...state,
        users: updateUsers,
      };
    }

    case 'SAVE__ALL__BADGE': {
      if ((action.payload as Array<Badge>)?.length === 0) {
        return {
          ...state,
          badges: [],
        };
      }

      return {
        ...state,
        badges: [...(action.payload as Array<Badge>)],
      };
    }

    case 'CREATE__BADGE': {
      const copyBadge = [...state.badges];
      const updateBadges = [...copyBadge, { ...(action.payload as object) }];

      return {
        ...state,
        badges: updateBadges,
      };
    }

    case 'UPDATE__USER': {
      const copyArray = [...state.users];
      const User = action.payload as any;
      const isUserExist = copyArray.findIndex((user) => {
        if ('idUtente' in user) {
          return User.idUtente === user.idUtente;
        }
      });

      // if exist
      if (isUserExist >= 0) {
        copyArray.splice(isUserExist, 1, {
          ...User,
        });
        return {
          ...state,
          users: [...copyArray],
        };
      } else {
        return {
          ...state,
          users: [...copyArray],
        };
      }
    }

    case 'SAVE__ALL__NOTE': {
      if ((action.payload as Array<Note>)?.length === 0) {
        return {
          ...state,
          notice: [],
        };
      }

      return {
        ...state,
        notice: [...(action.payload as Array<Note>)],
      };
    }

    case 'SAVE__ALL__NOTIFICATION': {
      if ((action.payload as Array<Notification>)?.length === 0) {
        return {
          ...state,
          notification: [],
        };
      }

      return {
        ...state,
        notification: [...(action.payload as Array<Notification>)],
      };
    }

    case 'UPDATE__NOTE': {
      const copyArray = [...state.notice];
      const notice = action.payload as any;
      const isNoteExist = copyArray.findIndex((note) => {
        return note.uuid === notice.uuid;
      });

      // if exist
      if (isNoteExist >= 0) {
        copyArray.splice(isNoteExist, 1, {
          ...notice,
        });
        return {
          ...state,
          notice: [...copyArray],
        };
      } else {
        return {
          ...state,
          notice: [...copyArray],
        };
      }
    }

    case 'DELETE__USER': {
      const copyArray = [...state.users];
      const User = action.payload as any;
      const isUserExist = copyArray.findIndex((user) => {
        if ('idUtente' in user) {
          return User.idUtente === user.idUtente;
        }
      });

      // if exist
      if (isUserExist >= 0) {
        copyArray.splice(isUserExist, 1);
        return {
          ...state,
          users: [...copyArray],
        };
      }

      return {
        ...state,
        users: [...copyArray],
      };
    }

    case 'DISABLES__USERS': {
      const copyArray = [...state.users];
      const newArray: Array<User | []> = copyArray.filter((user) => {
        if ('disabled' in user) {
          return user.disabled === true;
        }
      });

      return {
        ...state,
        users: [...newArray],
      };
    }

    case 'DELETE__BADGE': {
      const copyArray = [...state.badges];
      const BadgeIndex = copyArray.findIndex((badge) => {
        return (badge.uuid as string) === action.payload;
      });

      if (BadgeIndex >= 0) {
        copyArray.splice(BadgeIndex, 1);
        return {
          ...state,
          badges: [...copyArray],
        };
      } else {
        return {
          ...state,
          badges: [...copyArray],
        };
      }
    }

    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
      };
    }

    default:
      return state;
  }
};

export default Reducer;
