import './NotFound.scss';
import Logo from '../../Assets/Icons/Logo__light.png';
import { Link } from 'react-router-dom';
import Button from '../../Reusable/Button/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../../Assets/Images/404.jpeg';

const NotFound = () => {
  const isAuthenticated = useSelector((state: RootState) => state.root.isAuthenticated);
  const navigate = useNavigate();

  // go back
  const goBackHandler = () => {
    if (isAuthenticated) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className='NotFound'>
      <header className='NotFound__header'>
        <Link to='/'>
          <img className='NotFound__header-image' src={Logo} alt='logo' />
        </Link>
      </header>
      <div className='NotFound__content container'>
        <div className='row items-center'>
          <div className='NotFound__col col-xs-12 col-lg-6'>
            <h1 className='NotFound__title'>Risorsa non trovata</h1>
            <p className='NotFound__description'>
              Non è stato possibile reperire la risorsa ricercata. Torna all'homepage
            </p>
            <div className='NotFound__btn'>
              <Button title='Torna indietro' variant='primary' size='lg' onClick={goBackHandler} />
            </div>
          </div>
          <div className='NotFound__col col-xs-12 col-sm-10 col-md-8 col-lg-6'>
            <img src={NotFoundImage} alt='404' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
