import { Fragment, useCallback, useEffect, useState } from 'react';
import './Dashboard.scss';
import useHttp from '../../Hooks/useHttp';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncLogout, AsyncSaveAllUsers } from '../../Redux/Actions/Actions';
import { Transition } from 'react-transition-group';
import Notification from '../../Reusable/Notification/Notification';
import Loader from '../../Reusable/Loader';
import DashboardCard from '../../Containers/DashboardCard/DashboardCard';
import { RootState } from '../../Redux/Store';
import Analyctis from '../../Assets/Icons/analytics.png';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardChart from './DashboardChart/DashboardChart';
import { Admin } from '../../Interface/Interface';
import { useNavigate } from 'react-router-dom';

const Dashboard = (): JSX.Element => {
  const { httpRequest, loader } = useHttp();
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [usersLength, setUsersLength] = useState<number>(0);
  const [badgesLength, setBadgesLength] = useState<number>(0);
  const [noticesLength, setNoticesLength] = useState<number>(0);
  const [dataGraph, setDataGraph] = useState<Array<number>>([0]);
  const dispatch = useDispatch();
  const admin = useSelector((state: RootState) => state.root.admin);
  const navigate = useNavigate();
  const { name, surname } = admin as Admin;

  // get dashboard data
  const getDashboardData = useCallback(async () => {
    const response: any = await Promise.all([
      httpRequest(process.env.REACT_APP_DASHBOARDDATA__API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
      httpRequest(process.env.REACT_APP_GRAPHDATA__API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }),
    ]);

    // if response one go to error
    if (typeof response[0] === 'function') {
      if (response().status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // send message
      setStatusMessage(true);
      setType('Error');
      setMessage(response().error);
    }

    // if response second go to error
    if (typeof response[1] === 'function') {
      if (response().status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      // send message
      setStatusMessage(true);
      setType('Error');
      setMessage(response().error);
    }

    // update state
    dispatch(AsyncSaveAllUsers(response[0].users));
    setUsersLength(response[0].users.length);
    setBadgesLength(response[0].badges);
    setNoticesLength(response[0].notices);
    setDataGraph([...response[1].graph]);
  }, [httpRequest, dispatch]);

  // effect
  useEffect(() => {
    getDashboardData();
    if (localStorage.getItem('path')) {
      const path = localStorage.getItem('path');
      localStorage.removeItem('path');
      navigate(`/${path}`);
    }
  }, [getDashboardData]);

  // close notification
  const closeNotificationHandler = useCallback(() => {
    setStatusMessage(false);
  }, []);

  return (
    <Fragment>
      <Transition in={statusMessage} timeout={500} appear unmountOnExit>
        {(state) => (
          <Notification
            type={type}
            message={message}
            onClose={closeNotificationHandler}
            animation={`notificationLeft--${state}`}
          />
        )}
      </Transition>
      <main className='Dashboard'>
        {!loader && (
          <Fragment>
            <div className='row'>
              <article className='Dashboard__data col-xs-12 col-sm-6 col-xxl-3'>
                <DashboardCard
                  type='introduction'
                  title={`Bentornato ${name + '' + surname}`}
                  subtitle='Mtm dashboard'
                  image={Analyctis}
                />
              </article>
              <article className='Dashboard__data col-xs-12 col-sm-6 col-xxl-3'>
                <DashboardCard
                  title={String(usersLength)}
                  subtitle='Utenti totali piattaforma'
                  icon={<PeopleAltIcon className='DashboardCard__boxIcon-icon' />}
                  navigateTo='/Home/Panoramica'
                />
              </article>
              <article className='Dashboard__data col-xs-12 col-sm-6 col-xxl-3'>
                <DashboardCard
                  title={String(badgesLength)}
                  subtitle='Badge totali'
                  icon={<BadgeIcon className='DashboardCard__boxIcon-icon' />}
                  navigateTo='/Home/Badge'
                />
              </article>
              <article className='Dashboard__data col-xs-12 col-sm-6 col-xxl-3'>
                <DashboardCard
                  type='data'
                  title={String(noticesLength)}
                  subtitle='Notizie blog aziendale'
                  icon={<AssignmentIcon className='DashboardCard__boxIcon-icon' />}
                  navigateTo='/Home/Avvisi'
                />
              </article>
            </div>
            <div className='Dashboard__chart'>
              <DashboardChart dataGraph={dataGraph} />
            </div>
          </Fragment>
        )}
        {loader && <Loader />}
      </main>
    </Fragment>
  );
};

export default Dashboard;
