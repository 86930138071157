import { useEffect } from 'react';
import './Notification.scss';
import { NotificationProps } from '../../Interface/PropsInterface';
import Error from '../../Assets/Icons/error.png';
import Success from '../../Assets/Icons/success.png';
import Info from '../../Assets/Icons/information.png';

const Notification = ({
  type,
  message,
  onClose,
  animation,
  direction,
}: NotificationProps): JSX.Element => {
  // effect for set timeout close notification
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [type, onClose]);

  // render icon
  const renderIcon = (): any => {
    switch (type.toUpperCase()) {
      case 'SUCCESS':
        return Success;

      case 'ERROR':
        return Error;

      default:
        return Info;
    }
  };

  return (
    <div className={`Notification notification${direction}--${animation}`}>
      <div className='Notification__content'>
        <img className='Notification__content-icon' src={renderIcon()} alt='type message' />
        <h1 className='Notification__content-title'>{message}</h1>
      </div>
    </div>
  );
};

export default Notification;
